import axios from "../../utilities/axios";
import { Event } from "../Event"
import dayjs from "dayjs";
import { Notification } from "rsuite";

export class OrderTablePriceFkPriceIdEvents extends Event {

    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        let initialData = this.globalProps?.values?.items ?? []
        this.setState('loadings', ['items'])
    
        for (var i in initialData) {
            let item = initialData[i]

            await axios.get("/api/v1/products/" + item?.item_fk_product_id + "?selectTabPreco=" + value).then((res) => {
                let item = res?.data?.item
                if (res?.data?.item?.selectTabPreco) {
                    initialData[i]['item_unitary_value'] = item?.selectTabPreco?.mbm_price_seller_price
                }
            })
        }

        this.setFieldValue("items", initialData)
        this.setState('loadings', [])
        if(initialData?.length > 0){
            Notification.success({
                title: "Preços Atualizados",
                description: `Com Sucesso`
            })
    
        }
     

        // axios.get("/api/v1/products/" + value + "?selectTabPreco=" + value).then((res) => {
        //     let item = res?.data?.item
        //     if (res?.data?.item?.selectTabPreco) {
        //         setField("item_unitary_value", item?.selectTabPreco?.mbm_price_seller_price);
        //     } else {
        //         setField("item_unitary_value", item?.product_sale_price);
        //         Notification.info({
        //             title: "Atenção !",
        //             description: `Item não encontrado na Tabela de Preço!`
        //         })
        //     }


        // }).finally(() => {
        //     this.setState('loadings', [])
        // })
    }
}
