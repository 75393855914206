import React from "react";
import { diff } from "json-diff";
import { Button, Table } from "rsuite";
import classNames from "classnames";
import dayjs from "dayjs";
const { Column, HeaderCell, Cell } = Table;

export class Differencify extends React.Component<any, {}> {
    public state: any = {
        tableWidth: 0,
        expandedRowKeys: [],
    };

    public differenceData = diff(this.props.old != null ? this.props.old : {}, this.props.new);

    public tableRef: any = null;

    constructor(props: any) {
        super(props);
        this.tableRef = React.createRef();
    }

    componentDidMount() {
        this.setState({ tableWidth: this.tableRef.current.offsetWidth });
    }

    renderRow() {
        var output = [
            <div className="diff-row">
                <div className="key bold">Campo</div>
                <div className="column bold">Valor antigo</div>
                <div className="column bold">Valor novo</div>
            </div>,
        ];

        for (var property in this.differenceData) {
            var data = this.differenceData[property];
            if (property.includes("updated_at") || property.includes("updated_by")) {
                continue;
            }
            if (typeof this.differenceData[property] === "object") {
                output.push(
                    <div className="diff-row">
                        <div className="key">{property}</div>
                        <div className="old">- {data?.__old}</div>
                        <div className="new">+ {data?.__new}</div>
                    </div>,
                );
            } else {
                // if(property.includes("updated_by")) continue;
                output.push(
                    <div className="diff-row">
                        <div className="key">{property.split("__").shift()}</div>
                        <div className="old"></div>
                        <div className="new">{data}</div>
                    </div>,
                );
            }
        }
        return output;
    }

    getTextWidth = (text: string, fontsize: number) => {
        return text.length * 11.2;
    };

    openRow = (rowIndex: any) => {
        var openedRows = this.state.expandedRowKeys;
        if (openedRows.includes(rowIndex)) {
            openedRows.splice(openedRows.indexOf(rowIndex), 1);
        } else {
            openedRows.push(rowIndex);
        }
        this.setState({ expandedRowKeys: openedRows });
    };

    renderColumns = () => {
        var output = [
            <Column width={40}>
                <HeaderCell>#</HeaderCell>
                <Cell>
                    {(rowData: any, index: any) => {
                        var iconType = this.state.expandedRowKeys.includes(index) ? "fa-minus" : "fa-plus";
                        return (
                            <Button
                                onClick={() => {
                                    this.openRow(index);
                                }}
                                size={"xs"}
                                appearance={"subtle"}>
                                <i className={classNames("fas", iconType)}></i>
                            </Button>
                        );
                    }}
                </Cell>
            </Column>,
        ];
        var targetWidth = this.state.tableWidth / (Object.keys(this.props.fields).length + 1);
        for (let i in this.props.fields) {
            if (i !== "$GLOBALS") {
                
                var field = this.props.fields[i];
                var label = field.label ?? field.name;
                var currentTargetWidth = targetWidth;
                var labelWidth = this.getTextWidth(label, 12);
                if (label) {
                    if (currentTargetWidth < labelWidth) {
                        currentTargetWidth = labelWidth;
                    }
                }
                // targetWidth = this.getTextWidth(field.label, 12);
                output.push(
                    <Column key={i} width={currentTargetWidth} resizable>
                        <HeaderCell>
                            {/* {currentTargetWidth}/{this.getTextWidth(label, 12)} */}
                            <span dangerouslySetInnerHTML={{ __html: label }}></span>
                        </HeaderCell>
                        <Cell>
                            {(row: any, index: any, col: any) => {
                                var expanded = this.state.expandedRowKeys.includes(index);
                                if (typeof row[i] == "boolean") {
                                    row[i] = row[i] ? "Sim" : "Não";
                                }
                                if (typeof row["__old"][i] == "boolean") {
                                    row["__old"][i] = row["__old"][i] ? "Sim" : "Não";
                                }
                                if (expanded) {
                                    return (
                                        <div>
                                            <div>{row[i]}</div>
                                            <div style={{ color: "red" }}>- {row["__old"][i]}</div>
                                        </div>
                                    );
                                }
                                return row[i];
                            }}
                        </Cell>
                    </Column>,
                );
            }
        }
        output.push(
            <Column key={"data"} width={targetWidth - 40} resizable>
                <HeaderCell>Data</HeaderCell>
                <Cell>
                    {(row: any, index: any, col: any) => {
                        return (
                            <div>
                                <div>{dayjs(row["__data"]).format("DD/MM/YYYY")}</div>
                                <div>{dayjs(row["__data"]).format("HH:mm:ss")}</div>
                            </div>
                        );
                    }}
                </Cell>
            </Column>,
        );
        output.push(
            <Column key={"user"} width={targetWidth - 40} resizable>
                <HeaderCell>Usuário</HeaderCell>
                <Cell>
                    {(row: any, index: any, col: any) => {
                        return row["__user"];
                    }}
                </Cell>
            </Column>,
        );
        return output;
    };

    parseLogsToRow() {
        //(this.props.logs);
        const output: any[] = [];
        for(const item of this.props.logs) {
            output.push({ __old: item.log_old_data, __user: item.log_created_by, __data: item.log_created_at, ...item.log_new_data });
        }
        return output;
    }

    render() {
        return (
            <div className="diff" ref={this.tableRef}>
                <Table
                    rowExpandedHeight={200}
                    height={400}
                    rowHeight={60}
                    renderRowExpanded={(rowData) => {
                        return (
                            <div>
                                <div style={{ height: 200, width: 400 }}>olá</div>
                            </div>
                        );
                    }}
                    data={this.parseLogsToRow()}>
                    {this.renderColumns()}
                </Table>
            </div>
        );
    }
}
