import React from "react";
import { Notification } from "rsuite";
import { io } from "../../utilities/io";
import { AuthContext } from "../authProvider/main";

export default class SocketIoEvents extends React.Component<any, {}> {
    static contextType?: React.Context<any> | undefined = AuthContext;
    public state = {
        test: "",
    };

    componentDidMount() {
        if (this.props.user?.data?.user_id) {
            io.on("notification", (msg: any) => {
                // this.setState({test: JSON.stringify(this.props.user)})
                if (msg.users.includes(parseInt(this.props.user?.data?.user_id))) {
                    Notification.info({
                        title: "Notificação",
                        description: msg.message,
                        placement: "bottomEnd",
                    });
                }
            });
        }
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (this.props.user?.data?.user_id) {
            io.removeListener("notification");
            io.on("notification", (msg: any) => {
                // this.setState({test: JSON.stringify(this.props.user)})
                if (msg.users.includes(parseInt(this.props.user?.data?.user_id))) {
                    Notification.info({
                        title: "Notificação",
                        description: msg.message,
                        placement: "bottomEnd",
                    });
                }
            });
        }
    }

    componentWillUnmount() {
        io.removeListener("notification");
    }

    render() {
        return <>{this.state.test}</>;
    }
}
