import { DeveloperProductEvents } from "./pages/developers/DeveloperProductEvents";
import EstimateBrowseEvents from "./pages/estimates/EstimateBrowseEvents";
import CommercialBrowseEvents from './pages/labs/CommercialBrowseEvents'
import { PsmEquipmentEvents } from "./pages/psm_equipments/PsmEquipmentsActions";
import { PsmRegistrationProductEvents } from "./pages/psm_status_logs/PsmRegistrationProductEvents";


export const CustomExtensions = {
    EstimateBrowseEvents,
    DeveloperProductEvents,
    PsmRegistrationProductEvents,
    PsmEquipmentEvents,
    CommercialBrowseEvents
};
