import axios from "../../utilities/axios";
import { Event } from "../Event"
import dayjs from "dayjs";

export class ClientFkStateIdEvents extends Event {
    // calculationMaxLength = (mask: string[] | undefined) => {
    //     if (Array.isArray(mask) && mask !== undefined) {
    //         let maxLength = 0
    //         let maskValue = ""
    //         for (let i = 0; i < mask.length; i++) {
    //             let value = mask[i].length;
    //             if (value > maxLength) {
    //                 maxLength = value;
    //                 maskValue = mask[i]
    //             }
    //         }
    //         return maskValue.replace(/[^0-9]/g, '').length;
    //     }
    // }

    // public onChange = (value: any, setField: any, setState: any, formValues: any) => {
    //     var mask: string[] | undefined = undefined;
    //     switch (value) {
    //         case "4":
    //             mask = ["99.999.999/999-99"];
    //             break;
    //         case "5":
    //             mask = ["99999999-9"];
    //             break;
    //         case "6":
    //             mask = ["99.999.999-9"];
    //             break;
    //         case "7":
    //             mask = ["99.999999-9"];
    //             break;
    //         case "8":
    //             mask = ["999.999.99"];
    //             break;
    //         case "9":
    //             mask = ["99.999999-9"];
    //             break;
    //         case "10":
    //             mask = ["99999999999-99"];
    //             break;
    //         case "11":
    //             mask = ["99999999999-99"];
    //             break;
    //         case "12":
    //             mask = ["99.999.999-9"];
    //             break;
    //         case "13":
    //             mask = ["99.999.999-9"];
    //             break;
    //         case "14":
    //             mask = ["999.999.999-9999"];
    //             break;
    //         case "15":
    //             mask = ["999.999.999-9999"];
    //             break;
    //         case "16":
    //             mask = ["9999999999-9"];
    //             break;
    //         case "17":
    //             mask = ["99-999999-9"];
    //             break;
    //         case "18":
    //             mask = ["99999999-9"];
    //             break;
    //         case "19":
    //             mask = ["99.9.999.9999999-9"];
    //             break;
    //         case "20":
    //             mask = ["99.9.999.9999999-9"];
    //             break;
    //         case "21":
    //             mask = ["99999999-99"];
    //             break;
    //         case "22":
    //             mask = ["99.999.99-9"];
    //             break;
    //         case "23":
    //             mask = ["99.999.999-9"];
    //             break;
    //         case "24":
    //             mask = ["999.99999-9"];
    //             break;
    //         case "25":
    //             mask = ["99999999-9"];
    //             break;
    //         case "26":
    //             mask = ["999/9999999"];
    //             break;
    //         case "27":
    //             mask = ["999.999.999"];
    //             break;
    //         case "28":
    //             mask = ["999.999.999.999"];
    //             break;
    //         case "29":
    //             mask = ["999999999999999999"];
    //             break;
    //         case "30":
    //             mask = ["999999999999999999"];
    //             break;
    //         case "39":
    //             mask = ["999999999999999999"];
    //             break;
    //     }

    //     this.setFieldProperty("client_state_ins", "mask", mask);

    //     this.setField("client_state_ins", {
    //         mask: mask,
    //         readonly: false,
    //         required: true,
    //         disabledMask: false
    //     })

    //     this.setState('loadings', [
    //         'user_fk_state_id'
    //     ])
    //     this.setFieldValue("user_fk_state_id", value);
    //     this.setState('loadings', [])
    // };
}
