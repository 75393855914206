import React from "react";
import { withRouter } from "react-router";
import EditPage from "./EditPage";
class ReadPage extends React.Component<any, {}> {
    render() {
        return <EditPage view={true} />
    }
}

export default withRouter(ReadPage);
