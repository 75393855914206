import classNames from "classnames";
import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Dropdown, Icon, Input, Loader, Modal, Table } from "rsuite";
import { isInPath } from "../../utilities/isInPath";
import { AuthContext, ModuleContext, UserContext } from "../authProvider/main";
import { ModuleShortcuts } from "./moduleShortcut";
import { NotificationsIcon } from "../notifications/main";
import { BreadCrumbs } from "../breadcrumbs/main";
import _, { toLower } from "lodash";
import axios from "../../utilities/axios";
import { error } from "console";

const { Column, HeaderCell, Cell } = Table;
// import { SearchBar } from "../search-bar/main";

class InnerTopbar extends React.Component<any, {}> {
    // static contextType = ModuleContext;
    public state: any = {
        showModalCompany: false,
        currentCompany: localStorage?.getItem("currentCompany") ?? "",
        companies: [],
        selectedCompany: localStorage?.getItem("currentCompanyName") ?? "",
        companiesFilter: []
    }

    logout = () => {
        this.props.auth.setAuth(null);
        localStorage.removeItem("currentCompany")
        localStorage.removeItem("currentCompanyName")
        localStorage.removeItem("logosrc")
        this.props.history.push("/login");
    };

    goBack = () => {
        this.props.history.push("/dashboard");
    };

    onModuleClick = (moduleName: string) => {
        this.props.history.push("/dashboard/" + moduleName);
    };

    getCompanies = async () => {
        await axios.get('api/v1/user/companies')
            .then((res) => {
                this.setState({
                    companies: res.data.data,
                    companiesFilter: res.data.data
                })
            })
            .catch(() => {
                this.logout()
            })
    }

    componentDidMount = () => {
        this.getCompanies()
    }

    componentDidUpdate() {
        const { selectedCompany } = this.state
        const localCompany = localStorage.getItem("currentCompanyName")
        if (selectedCompany !== localCompany) {
            this.setState({
                selectedCompany: localCompany
            })
        }
    }

    renderProfileButton() {
        var componentDeTeste = () => {
            if (this.props.user.data) {
                const { selectedCompany } = this.state
                return (
                    <div className="clickable" style={{ textTransform: "capitalize", fontSize: 18, fontWeight: "bold", textAlign: "left", minWidth: 100 }}>
                        <div style={{ fontSize:"50%",margin:"auto", display: "flex",wordWrap:"break-word" }}>
                            <i onClick={this.goBack} className="fas fa-fw fa-cog mr-2"></i>
                            <p className="hidden-xs">
                                {this.props.user.data.user_name} | 
                            </p>
                            {selectedCompany}
                        </div>
                    </div>
                );
            } else {
                return null;
            }
        };

        return (
            <Dropdown placement="bottomEnd" renderTitle={componentDeTeste}>
                {/* <Dropdown.Item onSelect={() => this.props.history.push("/dashboard/configuracoes/profile")}>
                    <i className="fas fa-fw fa-user icon"></i> Meu perfil
                </Dropdown.Item> */}
                <Dropdown.Item onSelect={() => this.setState({ showModalCompany: true })}>
                    <i className="fas fa-fw fa-sync icon"></i> Trocar Empresa
                </Dropdown.Item>
                <Dropdown.Item onSelect={this.logout}>
                    <i className="fas fa-fw fa-sign-out-alt icon"></i> Sair
                </Dropdown.Item>
            </Dropdown>
        );
    }

    renderModulesButton() {
        return (
            <div onClick={this.goBack} className="clickable" style={{ textTransform: "capitalize", fontSize: 18, fontWeight: "bold", minWidth: 200, textAlign: "right" }}>
                <i className="fas fa-fw fa-th-large mr-2"></i>
                {this.props.module.name ? this.props.module.name.split("-").join(" ") : ""}
            </div>
        );
    }

    public ioSubscription: any = null;

    onSelectCompany(rowData: any) {
        localStorage.setItem("currentCompany", rowData?.value)
        localStorage.setItem("currentCompanyName", rowData?.label)
        if (rowData?.company?.mbm_company_src_logo?.length > 5) {
            localStorage.setItem("logosrc", rowData?.company?.mbm_company_src_logo)
        } else {
            localStorage.removeItem('logosrc')
        }
        this.setState({ showModalCompany: false, currentCompany: rowData?.value })
        this.props.history.push("/dashboard")
    }

    render() {
        if (!this.props.auth.token && !this.props.module.name) {
            return null;
        }
        let { companies, companiesFilter } = this.state

        return (
            <div className={classNames("topbar", this.props.fulld ? "topbar-fulld" : this.props.full ? "topbar-full" : "")} style={{ backgroundColor: this.props.module.color }}>
                <Modal show={!localStorage.getItem("currentCompany") || this.state.showModalCompany} size="lg">
                    <Modal.Header>
                        <b>  Selecione Uma Empresa  </b>
                    </Modal.Header>
                    <Modal.Body>
                        <Input style={{ marginBottom: 8 }} onChange={(value) => {
                            let filtered = _.filter(companies, function (o) {
                                return (toLower(o.label)).includes(toLower(value)) || (toLower(o.company?.mbm_company_cpf_cnpj)).includes(toLower(value))
                            })
                            this.setState({ companiesFilter: filtered ?? companies })
                        }} placeholder="Filtrar Empresa..."></Input>
                        
                        {companies.length > 0 ? (
                            <Table height={400} data={companiesFilter}>
                                {/* <Column flexGrow={0} align="left">
                                    <HeaderCell>#</HeaderCell>
                                    <Cell>
                                        {(rowData: any) => {
                                            return (
                                                ((rowData?.company?.mbm_company_code)?.toString())
                                            )
                                        }}
                                    </Cell>
                                </Column> */}
                                <Column flexGrow={2} align="left">
                                    <HeaderCell>Codigo</HeaderCell>
                                    <Cell>
                                        {(rowData: any) => {
                                            return (
                                                ((rowData?.company?.mbm_company_code) ?? "")
                                            )
                                        }}
                                    </Cell>
                                </Column>
                                <Column flexGrow={2} align="left">
                                    <HeaderCell>CNPJ</HeaderCell>
                                    <Cell>
                                        {(rowData: any) => {
                                            return (
                                                (((rowData?.company?.mbm_company_cpf_cnpj)?.toString())?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5') ?? "")
                                            )
                                        }}
                                    </Cell>
                                </Column>
                                <Column flexGrow={4} align="left">
                                    <HeaderCell>Nome</HeaderCell>
                                    <Cell dataKey="label" />
                                </Column>
                                <Column flexGrow={4} align="center">
                                    <HeaderCell> </HeaderCell>
                                    <Cell>
                                        {(rowData: any) => {
                                            return (<>
                                                <div style={{ marginTop: -10 }}>
                                                    <Button
                                                        onClick={() => {
                                                            this.onSelectCompany(rowData)
                                                        }}
                                                        appearance="primary" >
                                                        {/* <Icon icon={'check-circle'} /> */}
                                                        Selecionar
                                                    </Button>
                                                </div>
                                            </>)
                                        }}
                                    </Cell>
                                </Column>
                            </Table>
                        ) : (
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Loader size="lg" />
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
                <div style={{ width: "33%", display: "flex", alignItems: "center" }}>
                    {isInPath(this.props.location.pathname, "/dashboard") ? null : <i onClick={() => this.props.changeSidebar()} className={"fas fa-bars"}></i>}
                    <NotificationsIcon />

                    <BreadCrumbs />
                </div>

                {isInPath(this.props.location.pathname, "/dashboard") ? <div></div> : <ModuleShortcuts onClick={this.onModuleClick} />}
                {/* <div style={{ width: "33%", textAlign: "right" }}>{isInPath(this.props.location.pathname, "/dashboard") ? this.renderProfileButton() : this.renderModulesButton()}</div> */}
                <div style={{ width: "33%", textAlign: "right" }}>{this.renderProfileButton()}</div>
            </div>
        );
    }
}

class Topbar extends React.Component<any, {}> {
    render() {
        return (
            <ModuleContext.Consumer>
                {(moduleContext) => {
                    return (
                        <AuthContext.Consumer>
                            {(auth) => {
                                let moduleVar: any = moduleContext;
                                if (isInPath(this.props.location.pathname, "/dashboard")) {
                                    moduleVar = { name: "", setModule: () => { } };
                                }
                                return (
                                    <UserContext.Consumer>
                                        {(user: any) => {
                                            // var timeout = setTimeout(() => {

                                            // })
                                            if (user.data?.user_name) {
                                                return (
                                                    <InnerTopbar
                                                        changeSidebar={() => this.props.changeSidebar()}
                                                        fulld={isInPath(this.props.location.pathname, "/dashboard")}
                                                        full={this.props.hiddenSidebar}
                                                        location={this.props.location}
                                                        history={this.props.history}
                                                        module={moduleVar}
                                                        auth={auth}
                                                        user={user}
                                                    />
                                                );
                                            } else {
                                            }
                                        }}
                                    </UserContext.Consumer>
                                );
                            }}
                        </AuthContext.Consumer>
                    );
                }}
            </ModuleContext.Consumer>
        );
    }
}

export default withRouter(Topbar);
