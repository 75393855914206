import { Event } from "../Event";

export class PersonTypeIsCustomerEvents extends Event {
    public onChange = (value: any, setField: any) => {
        //alert('ok')  
        if (value) {
            //alert('here')
            setField("person_type_is_supply", false);
            setField("person_type_is_shipping", false);
        }
      };
}
