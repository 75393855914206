export class PersonSettings {
    static defaults = {
        person_fk_country_id: { value: "263", label: "BRASIL" },
    };


    static exteriorValidation(field : any,value:any,values:any) {
        var fieldCopy = { ...field };
        var isExterior = PersonSettings.isExterior(values);
        if (isExterior) {
            fieldCopy.required = !isExterior;
            fieldCopy.disabled = isExterior;
        }
        return fieldCopy;
    }
    static interiorValidation(field : any,value:any,values:any) {
        var fieldCopy = { ...field };
        var isExterior = PersonSettings.isExterior(values);
        if (isExterior) {
            fieldCopy.required = isExterior;
            fieldCopy.hidden = !isExterior;
        }
        return fieldCopy;
    }

    static exteriorMask(field : any,value:any,values:any) {
        var fieldCopy = { ...field };
        var isExterior = PersonSettings.isExterior(values);
        if (isExterior) {
            fieldCopy.mask = undefined;
        }
        return fieldCopy;
    }

    static isExterior(values : any) {
        var isExterior = false;
        if (values.person_type_is_exterior !== undefined) {
            isExterior = values.person_type_is_exterior === true;
        }
        return isExterior;
    }

    static rules = {
        fields: {
            person_cnpj_cpf: PersonSettings.exteriorValidation,
            person_zip_code: PersonSettings.exteriorValidation,
            //person_district: PersonSettings.exteriorValidation,
            //person_street: PersonSettings.exteriorValidation,
            //person_number: PersonSettings.exteriorValidation,
            //person_fk_state_id: PersonSettings.exteriorValidation,
            // person_fk_city_id: PersonSettings.exteriorValidation,
            // person_city_ex: PersonSettings.interiorValidation,
            // person_state_ex: PersonSettings.interiorValidation,
            // person_street_ex: PersonSettings.interiorValidation,
            // person_phone_ex: PersonSettings.interiorValidation,
            person_principal_phone: PersonSettings.exteriorMask,
        },
    };
}
