import { Event } from "../Event";

export class DeveloperEvents extends Event {
    public onLoad = () => {
       
    }
}

export class DeveloperSelectEvents extends Event {
    public onChange = (value: any) => {
        var mask: string[] | undefined = undefined;
        switch (value) {
            case "4":
                mask = ["99.999.999/999-99"];
                break;
            case "6":
                mask = ["99.999.999-9"];
                break;
            case "8":
                mask = ["999999-99"];
                break;
            case "9":
                mask = ["99999999-9"];
                break;
            case "10":
                mask = ["99999999999-99"];
                break;
            case "12":
                mask = ["99.999.999-9"];
                break;
            case "14":
                mask = ["999.999.999-9999"];
                break;
            case "16":
                mask = ["9999999999-9"];
                break;
            case "17":
                mask = ["99-999999-9"];
                break;
            case "18":
                mask = ["99999999-9"];
                break;
            case "19":
                mask = ["99.9.999.9999999-9"];
                break;
            case "21":
                mask = ["99999999-99"];
                break;
            case "22":
                mask = ["99.999.99-9"];
                break;
            case "23":
                mask = ["99.999.999-9"];
                break;
            case "24":
                mask = ["999.99999-9"];
                break;
            case "25":
                mask = ["99999999-9"];
                break;
            case "26":
                mask = ["999/9999999"];
                break;
            case "27":
                mask = ["999.999.999"];
                break;
            case "28":
                mask = ["999.999.999.999"];
                break;
            case "29":
                mask = ["999999999-9"];
                break;
        }
        this.setFieldProperty("developer_string", "mask", mask);
    };
}

