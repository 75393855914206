//IMPORTAR O COMPONENTE
import { CreateLambdaComplement } from "./CreateLambdaComplement";
import { CreateTablesComplement } from "./CreateTablesComplement";
import { TableComplement } from "./TableComplement";
import { TableGridLambda } from "./TableGridLambda";
import { ValidatePage } from './ValidatePage'
import { TableNewDownloader } from './TableNewDownloader'
import { SelectCustom } from "./SelectCustom";
import { CheckCustom } from "./CheckCustom";
import { OrderCustom } from "./OrderCustom";
import { CustomImport } from "./CustomImport";

export default {
    CreateLambdaComplement,
    CreateTablesComplement,
    TableComplement,
    TableGridLambda,
    ValidatePage,
    TableNewDownloader,
    SelectCustom,
    CheckCustom,
    OrderCustom,
    CustomImport
// EXPORTAR O COMPONENTE
};
