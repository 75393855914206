import {Event} from "../Event";

export class PersonContactEvents extends Event {
    public onInit = () => {
        if(this.globalProps.parentValues) {
            const values = this.globalProps.parentValues;
            if(values.person_type_is_exterior == true) {
                this.setFieldProperty('person_contact_phone','mask',[]);
                this.setFieldProperty('person_contact_cellphone','mask',[]);
            }
        }
    }
}