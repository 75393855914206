import axios from "../../utilities/axios";
import { Event } from "../Event"
import dayjs from "dayjs";
import { Notification } from "rsuite";
// import axios from "axios";

export class OrderFkClientIdEvents extends Event {
  public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
    if (value > 0) {
      this.setState('loadings', ['order_table_price_fk_price_id', 'order_fk_mbm_condition_payment_id', 'order_payment_condition'])
      this.setFieldProperty("order_fk_client_shipment_id", "readonly", false);
      this.setFieldProperty("order_fk_client_charge_id", "readonly", false);

      axios.get("/api/v1/order/prices", {
        params: {
          client: value,
          currentCompany: Number(localStorage.getItem('currentCompany')),
        }
      }).then((res) => {
        if (res.data) {
          this.setFieldValue(
            "order_table_price_fk_price_id", (res.data.table ?? '')
          );

          this.setFieldValue(
            "order_fk_mbm_condition_payment_id", (res.data?.client?.typePayment?.mbm_condition_payment_id ?? '')
          );
          this.setFieldValue(
            "order_payment_condition", (res.data?.client?.typePayment?.mbm_condition_payment_description ?? '')
          );

          Notification.info({
            title: "Informação",
            description: res.data.text ?? ""
          })
        }
      }).finally(() => {
        this.setState('loadings', [])
      })


    }

  }
}
