import React from "react";
import { Loader, TagPicker } from "rsuite";
import axios from "../../utilities/axios";
import _ from "lodash";

export class ListInput extends React.Component<any, {}> {
    public state = {
        items: this.props.options ?? [],
        preLoadedData: this.props.options ?? [],
        loading: false,
        value: this.props.value ?? [],
    };

    onSearch = (value: any) => {};

    getItemFromId = (id: any) => {
        for (var i in this.state.items) {
            if (this.state.items[i].value === id) {
                return this.state.items[i];
            }
        }
        return null;
    };

    onSelect = (value: any) => {
        if (!value) {
            value = [];
        }
        const preLoadedData = value.map((item: any) => {
            // //
            return this.getItemFromId(item);
        });
        this.setState({ value, preLoadedData });
        // //
        if (this.props.onChange) {
            this.props.onChange(value, preLoadedData);
        }
    };

    componentDidMount() {
        // this.searchItems();
    }

    searchItems = (value: string | null = null) => {
        var uses = {};
        if (this.props.options) {
            if (this.props.options.length <= 0) {
                this.setState({ loading: true });
            }
        }
        if (this.props.api && this.props.api.url) {
            this.setState({ loading: true });
            if (this.props.api.uses) {
                uses = this.props.api.uses;
            }
            axios
                .get(this.props.api.url, { params: { search: value, concat: this.props.displayLabel ?? undefined, ...uses } })
                .then((res) => {
                    var arr = _.uniqBy([...this.state.preLoadedData, ...res.data.items], "value");

                    this.setState({ items: arr, loading: false });
                })
                .catch((e) => {
                    this.setState({ loading: false });
                })
                .finally(() => {
                    // var searchInput = document.getElementsByClassName("rs-picker-search-bar-input")[0] as HTMLInputElement;
                    // searchInput.focus();
                });
        }
    };

    render() {
        return (
            <>
                <TagPicker
                    className="w-100"
                    data={this.state.items}
                    defaultValue={this.state.value}
                    onSearch={this.searchItems}
                    onOpen={this.searchItems}
                    onChange={this.onSelect}
                    placeholder={"Selecione..."}
                    renderMenu={(menu) => {
                        if (this.state.loading) {
                            return (
                                <p style={{ padding: 4, color: "#999", textAlign: "center" }}>
                                    <Loader></Loader>
                                </p>
                            );
                        }
                        return menu;
                    }}
                />
            </>
        );
    }
}
