import React, { Component } from "react";
import { Table, Button, Input, Modal } from "rsuite";
import {ModalErrors} from './ModalErrors'

const { Column, HeaderCell, Cell, Pagination } = Table;

interface Props {
    onChangeFunction?: any;
    xmlData?: [];
    onLoading: boolean;

}

interface State {
    showModal: boolean;
    modalData: any
}

export class ValidateDuplicated extends Component<Props> {
    public state: State = {
        showModal: false,
        modalData: []
    }

    setModalData = (rowData: any, index: number) => {
        this.setState({
            showModal: true,
            modalData: { rowData, index }
        })
    }
    // renderBodyModal = (rowData: any) => {
    //     const output = [];
    //     for(const i in rowData) {

    //     }
    //     return
    // }

    renderModal = () => {
        const { index, rowData } = this.state.modalData

        return (<>
            <Modal show={this.state.showModal} size="lg" onHide={() => this.setState({ showModal: false})}>
                <Modal.Header>
                    <b> Logs de Erros </b>
                </Modal.Header>
                <Modal.Body>
                    {rowData?.validate == false && (<>
                        <Table
                            data={rowData.errors}
                        >
                            <Column flexGrow={2} align="left" >
                                <HeaderCell>Motivo</HeaderCell>
                                <Cell dataKey="nameColumn" />
                            </Column>
                            <Column flexGrow={2} align="left" >
                                <HeaderCell>Descrição do Erro</HeaderCell>
                                <Cell dataKey="valueError" />
                            </Column>
                        </Table>
                    </>)}
                </Modal.Body>
                <Modal.Footer>
                    <Button color="red" onClick={(e) => this.setState({showModal: false})}>Fechar</Button>
                </Modal.Footer>
            </Modal>

        </>)
    }

    render() {
        return (<>
            <ModalErrors
                showModal={this.state.showModal}
                modalData={this.state.modalData}
                funcaoclose={() => {
                    this.setState({ showModal: false });
                }}
            />
            <Table 
                data={this.props.xmlData} 
                loading={this.props.onLoading} 
                rowHeight={60}
                bordered
                height={400}
            >
                <Column flexGrow={1} align="left" fixed>
                    <HeaderCell>Validado</HeaderCell>
                    <Cell>
                        {(rowData: any) => {
                            if (rowData?.validate == false) {
                                return (<>
                                    <i className="fas fa-exclamation-circle"></i>
                                </>)
                            } else {
                                return (<>
                                    <i className="fas fa-check-circle"></i>
                                </>)
                            }
                        }}
                    </Cell>
                </Column>
                <Column flexGrow={2} align="left" >
                    <HeaderCell>Nº xPed</HeaderCell>
                    <Cell>
                        {(rowData: any, index: number) => {
                            if (rowData.xPed_origin == '') {
                                return (<>
                                    <Input style={{ width: '70px' }} onChange={(event) => this.props.onChangeFunction('xPed_origin', event, index)} />
                                </>)
                            } else {
                                return (<>
                                    {rowData.xPed_origin}
                                </>)
                            }
                        }}
                    </Cell>
                </Column>
                <Column flexGrow={2}>
                        <HeaderCell>Nome do Arquivo</HeaderCell>
                        <Cell dataKey="name_xml_origin"/>
                </Column>
                <Column flexGrow={2}>
                        <HeaderCell>CNPJ Emitente</HeaderCell>
                        <Cell dataKey="emit_CNPJ_origin"/>
                </Column>
                <Column flexGrow={2}>
                        <HeaderCell>IE Emitente</HeaderCell>
                        <Cell dataKey="emit_IE_validate"/>
                </Column>
                <Column flexGrow={1} align="right">
                    <HeaderCell>Ações</HeaderCell>
                    <Cell>
                        {(rowData: any, index: any) => {
                            if (rowData?.validate == false) {
                                return (<>
                                    <Button onClick={(e) => this.setModalData(rowData, index)}>
                                        <i className="fas fa-eye" ></i>
                                    </Button>
                                </>)
                            }
                        }}
                    </Cell>
                </Column>

            </Table>

        </>)
    }
}