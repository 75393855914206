import axios from "../../utilities/axios";
import { Event } from "../Event"
import dayjs from "dayjs";

export class ItemUnitaryValue extends Event {
    public onChange = async (value: string, setField: any, setState: any, formValues: any) => {
        if (this.globalProps?.values?.item_unitary_value && this.globalProps?.values?.item_amount) {
            this.setState('loadings', ['item_total_value'])
            this.setFieldValue('item_total_value', (this.globalProps?.values?.item_unitary_value * this.globalProps?.values?.item_amount))

            this.setState('loadings', [])
        }
    }
}
