import _ from "lodash";
import axios from "../../utilities/axios";
import { Event } from "../Event"
import dayjs from "dayjs";

export class UserEvents extends Event { 

    public onLoad = (value: string, setField: any, setState: any, formValues: any) => {
        this.setState('loadings', ['user_fk_mbm_credential_id'])
        // console.log(this.globalProps)
        axios
            .get("/api/v1/me")
            .then((res) => {
                if (res.data) {
                    if (res.data.user.user_fk_role_id == 2) {
                        this.setField('user_fk_mbm_credential_id', {
                            readonly: false
                        })
                    }else{
                        this.setFieldValue('user_fk_mbm_credential_id',res.data.user.user_fk_mbm_credential_id)
                    }
                }
            })
            .catch((e) => console.log(e.message))
            .finally(() => {
                this.setState('loadings', [])
            });
    }
}