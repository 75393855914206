export class Event {
    public setGlobalState: Function | null = null;
    public callback: Function | null = null;
    public state: any = null;
    public globalState : any = null;
    public history : any = null;
    public values : any = null;
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {};
    public onBlur = (value: any, setField: any, setState: any, formValues: any) => {};
    public addTab = (tabKey: string,tabLabel: string,jsxComponent: any) => {};
    public setState: Function;
    public setFieldState: Function;
    public setFieldProperty: Function;
    public setFieldValue: Function;
    public globalProps: any = {};

    constructor(fieldName: string,state: any, setState: any,setFieldState: any,setFieldValue : any,setFieldProperty: any,route: any = null,match: any = null,props: any = {}, addTab: any = null) {

        this.globalProps = {...props}
        this.state = state.fieldStates[fieldName] ?? {};
        this.globalState = state;
        this.setState = setState;
        this.setFieldState = setFieldState;
        this.setFieldValue = setFieldValue;
        this.values = state.formValues;
        this.setFieldProperty = setFieldProperty;
        this.addTab = addTab;
        this.history = {route,match};
    }

    public setField = (fieldName: string, properties: any = {}) => {
        for(var i in properties) {
            this.setFieldProperty(fieldName,i,properties[i])
        }
    }


    public setValue = (key: string, value: any) => {
        // this.callback([key])
        if (this.setGlobalState) {
            this.setGlobalState((oldValues: any) => {
                var newFieldsUpdates = { ...oldValues.fieldsToUpdate };
                newFieldsUpdates[key] = true;
                var newValues = { ...oldValues.formValues };
                newValues[key] = value;
                return { formValues: newValues, fieldsToUpdate: newFieldsUpdates };
            });
        }
    };


    // public setState = (key: string, value: any) => {
    //     var obj: any = {};
    //     obj[key] = value;
    //     if (this.setGlobalState) {
    //         this.setGlobalState(obj);
    //     }
    // };

    public setProperties = (field: string, property: string, value: any) => {
        if (this.setGlobalState) {
            this.setGlobalState(function (oldValues: any) {
                var newData = { ...oldValues.formData };
                ////
                if (newData[field]) {
                    newData[field][property] = value;
                } else {
                    ////
                }
                return { formData: newData };
            });
        }
    };
}
