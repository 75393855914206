import axios from "../../utilities/axios";
import { Event } from "../Event"
import dayjs from "dayjs";

export class ClientEvents extends Event {
    public state: any = {
        currentCompany: Number(localStorage.getItem('currentCompany')),
    }


    public onLoad = (value: string, setField: any, setState: any, formValues: any) => {

        if (!this?.globalProps?.isEdit) {
            this.setState('loadings', ['client_fk_mbm_credential_id','client_fk_role_id'])
            axios
                .get("/api/v1/me?userShowCompany=true")
                .then((res) => {
                    if (res.data) {
                        if (!this.globalProps.isEdit) {
                            //CLIENTE
                            if (res.data.user?.user_fk_role_id == 4 && res.data?.client) {
                                this.setFieldValue(
                                    "client_fk_mbm_credential_id", res.data?.client?.user_fk_mbm_credential_id
                                );
                                this.setFieldValue(
                                    "client_fk_role_id", 4
                                );
                            }
                        }
                    }
                })
                .catch((e) => console.log(e.message))
                .finally(() => {
                    this.setState('loadings', [])
                });






        }
    }
}
