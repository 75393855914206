import classNames from "classnames";
import React from "react";
import {CSSTransition} from 'react-transition-group';

interface ModuleBlockProps {
    name: string;
    color: string;
    icon: string;
    delay: number;
    visible?: boolean;
    onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
}

export class ModuleBlock extends React.Component<ModuleBlockProps, {}> {

    public state = {
        didMount : false,
    }

    public nodeRef : any = null;

    constructor(props: any) {
        super(props);
        this.nodeRef = React.createRef();
    }

    componentDidMount = () => {
        this.setState({didMount: true})
    }

    render() {
        return (
            <CSSTransition nodeRef={this.nodeRef} in={this.state.didMount} timeout={(this.props.delay * 50) + 200} unmountOnExit classNames="anim-left" >
            <div onClick={this.props.onClick} className={classNames("module-block",this.props.visible === false ? 'no-display':'')} style={{ background: this.props.color }}>
                <div className="block-fader"></div>
                <div className="block-icon">
                    <i className={classNames("fas fa-fw", "fa-" + this.props.icon)}></i>
                </div>
                <div className="block-title">{this.props.name}</div>
            </div>
            </CSSTransition>
        );
    }
}
