import classNames from "classnames";
import dayjs from "dayjs";
import React from "react";
import { Badge, Loader, Popover, Tooltip, Whisper } from "rsuite";
import axios from "../../utilities/axios";
import { io } from "../../utilities/io";

class NotificationItem extends React.Component<any, {}> {
    renderTypeIcon() {
        switch (this.props.type) {
            default:
                return <i className="fas fa-info-circle text-primary"></i>;
            case "danger":
                return <i className="fas fa-times-octagon text-danger"></i>;
            case "warning":
                return <i className="fas fa-exclamation-triangle text-warning"></i>;
            case "success":
                return <i className="fas fa-check text-success"></i>;
        }
    }

    render() {
        return (
            <div className="notification" onClick={() => this.props.onClick(this.props.id, this.props.key)}>
                <div className="notification-icon">{this.renderTypeIcon()}</div>
                <div className="notification-text">{this.props.message}</div>
                <div className="notification-meta">{dayjs(this.props.date).format("DD/MM/YYYY HH:mm:ss")}</div>
            </div>
        );
    }
}

class Notifications extends React.Component<any, {}> {
    renderItems() {
        var output = [];

        for (var i in this.props.messages) {
            var data = this.props.messages[i];
            output.push(<NotificationItem onClick={this.props.onClick} key={i} id={data.id} type={data.type ?? "primary"} date={data.date} message={data.message} />);
        }
        return output;
    }

    render() {
        if (this.props.loading) {
            return <Loader speed={"slow"} size="md" />;
        }
        if (this.props.messages.length <= 0) {
            return <div style={{ opacity: 0.6 }}>Nenhuma notificação encontrada.</div>;
        }
        return this.renderItems();
    }
}

export class NotificationsIcon extends React.Component<any, {}> {
    public state = {
        messages: [],
        loading: false,
        hasNotification: false,
        isOpen: false,
    };
    componentDidMount() {
        this.loadMessages();
        io.on("newMessage", (message: object) => {
            //
            // alert(JSON.stringify(message));
            this.setState({ messages: [...this.state.messages], hasNotification: true });
        });
    }

    loadMessages = () => {
        this.setState({ loading: true });
        // if (this.state.messages.length <= 0) {
        axios.get("/api/redis/messages").then((res) => {
            if (res.data.result) {
                // if(res.data.result )
                this.setState({ messages: [...res.data.result], loading: false });
            }
        });
        // }
    };

    delete = (id: string | number, index: number) => {
        axios.get("/api/redis/read/message/" + id).then((res) => {
            if (res.data.status === 200) {
                // var messages = [...this.state.messages];
                // messages.splice(index,1);
                this.loadMessages();
                // this.setState({ messages },() => {});
            }
        });
        // alert("CLICK"+id);
    };

    render() {
        return (
            <>
                <Whisper
                    speaker={
                        <Popover>
                            <Notifications loading={this.state.loading} onClick={this.delete} messages={this.state.messages} />
                        </Popover>
                    }
                    trigger={"click"}
                    onClose={() => this.setState({ isOpen: false })}
                    onClick={() => {
                        this.setState({ hasNotification: false, isOpen: true });
                        this.loadMessages();
                    }}
                    // open={true}
                    placement={"bottomStart"}>
                    <span className="mr-4">
                        {/* {this.state.isOpen === false && this.state.hasNotification === true && (
                            <Tooltip visible={true} placement="bottomEnd">
                                Você possui novas notificações!
                            </Tooltip>
                        )} */}
                        <Badge maxCount={40}  content={this.state.messages.length > 0 ? this.state.messages.length : false}>
                            <i className="fas ml-4 mr-2 fa-fw fa-bell"></i>
                        </Badge>
                        {/* <i className={classNames("fas fa-fw ml-4 fa-bell")}>{this.state.hasNotification === true && <div className="bell-ball">•</div>}</i> */}
                    </span>
                </Whisper>

                {/* {JSON.stringify(this.state.messages)} */}
            </>
        );
    }
}
