import React from "react";
import { Button, Input, Loader, Message, Notification, Panel, Tooltip, Whisper } from "rsuite";
import { ConfirmOverlay } from "../../confirmOverlay/ConfirmOverlay";
import { Event } from "../../../events/Event";

export class OrderCustom extends React.Component<any, any> {
  public state = {
    qtdItems: 0,
    total: 0
  }
  columnItemStyle = {
    flex: "1",
    height: "80px",
    border: "1px solid #ccc"
  };

  title = {
    whiteSpace: "nowrap" as const,
    fontSize: "16px",
    borderBottom: "1px solid #ccc",
    padding: "5px"
  };

  componentDidMount(): void {
    this.updateValues();
  }

  componentDidUpdate(prevProps: any) {
    setTimeout(() => {
      this.updateValues();
    }, 2000)

  }

  updateValues() {
    let qtdItems = 0;
    let total = 0;
    if (this?.props?.values?.items) {
      for (const item of this?.props?.values?.items) {
        qtdItems += parseInt(item.item_amount);
        total += ((item.item_unitary_value || item.products.product_sale_price) * item.item_amount);
      }
      this.props.values.order_cost = total ?? 0
      this.props.values.order_quantity = qtdItems ?? 0
      this.setState({
        qtdItems,
        total
      });
    }
  }

  render() {
    const columnItemStyle = this.columnItemStyle;
    const title = this.title;
    let { qtdItems, total } = this.state || {};
    if (!total) {
      total = 0
    }

    return (
      <div>
         {/* <Message type="info" style={{textAlign:"center"}} description={(<b>Valor Sem Impostos</b>)} /> */}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", textAlign: "center", marginTop: "10px" }}>
            <div style={columnItemStyle}>
              <p style={title}>Quantidade de Itens</p>
              <h3>{qtdItems ?? 0}</h3>
            </div>
            <div style={columnItemStyle}>
              <p style={title}>Valor Total</p>              

              <h3>R$ {total?.toLocaleString("pt-BR", {
                currency: "BRL",
                minimumFractionDigits: 2,
              })}</h3>
              <b>* Valor Sem Impostos *</b>
            </div>
          </div>
        </div>

       
      </div>);
  }
}
