import axios from "../../utilities/axios";
import { Event } from "../Event"
import dayjs from "dayjs";

export class OrderEvents extends Event {
  public state: any = {
    currentCompany: Number(localStorage.getItem('currentCompany')),
  }


  public onLoad = (value: string, setField: any, setState: any, formValues: any) => {

    if (this?.globalProps?.isEdit) {
      if (this?.globalProps?.values?.order_fk_client_id > 0) {
        this.setFieldProperty("order_fk_client_shipment_id", "readonly", false);
        this.setFieldProperty("order_fk_client_charge_id", "readonly", false);
      }
      this.setFieldProperty("order_type", "readonly", true);
      this.setFieldProperty("order_fk_client_id", "readonly", true);
      this.setFieldProperty("order_fk_client_id", "advancedSearch", false);
    }

    this.setState('loadings', [
      'order_issue_date',
      'order_main_seller',
      'order_custom_math_items_values',
      'order_fk_mbm_credential_id',
      'order_fk_mbm_company_id',
      'order_typist',
      'order_fk_status_order_id',
      'order_type',
      'order_fk_client_id'
    ])

    if (!this?.globalProps?.isEdit) {
      const currentDate = dayjs().format('DD/MM/YYYY')

      this.setFieldValue("order_custom_math_items_values", this.globalProps?.values?.order_custom_math_items_values)

      this.setFieldValue(
        "order_issue_date", currentDate
      );
      this.setFieldValue(
        "order_delivery_date", currentDate
      );

      this.setFieldValue(
        "order_date_budget", dayjs().add(30, 'days').format('DD-MM-YYYY')
      );

      this.setFieldValue(
        "order_fk_status_order_id", 3
      );

      this.setFieldValue(
        "order_type", 2
      );

      this.setFieldValue(
        "order_fk_company_id", localStorage?.getItem("currentCompany")
      );


      axios.get("/api/v1/orders/latestorder").then((res: any) => {
        this.setFieldValue(
          "order_budget_number", ("00000" + res?.data?.next).slice(res?.data?.next?.length)
        );

      })
    }
    axios
      .get("/api/v1/me?userShowCompany=true")
      .then((res) => {
        if (res.data) {
          if (!this.globalProps.isEdit) {
            this.setFieldValue(
              "order_typist", res.data?.user?.user_name
            );
            this.setFieldValue(
              "order_main_seller", res.data?.user?.user_name
            );
            this.setFieldValue(
              "order_fk_mbm_company_id", this.state?.currentCompany
            );
          }

          //CLIENTE
          if (res.data.user?.user_fk_role_id == 4 && res.data?.client) {
            this.setFieldValue(
              "order_fk_mbm_credential_id", res.data?.client?.user_fk_mbm_credential_id
            );

            this.setFieldValue(
              "order_fk_client_id", res.data?.client?.user_fk_client_id
            );

            this.setFieldProperty("order_fk_client_id", "options", [{ label: res.data.client.user_name, value: res.data.client.user_fk_client_id }]);

            this.setFieldProperty("order_fk_client_id", "advancedSearch", false);
            this.setFieldProperty("order_fk_client_id", "readonly", true);


            this.setFieldProperty("order_fk_client_shipment_id", "readonly", false);
            this.setFieldProperty("order_fk_client_charge_id", "readonly", false);
          }
        }
      })
      .catch((e) => console.log(e.message))
      .finally(() => {
        this.setState('loadings', [])
      });






  }
}
