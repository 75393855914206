import classNames from "classnames";
import React from "react";

interface ModuleContainerProps {
    title: string;
    size: number;
}

export class ModuleContainer extends React.Component<ModuleContainerProps, {}> {
    render() {
        return (
            <div className="module-container">
                <div className="module-container-title">{this.props.title}</div>
                <div className="module-container-items">
                    <div className={classNames("block-container")}>
                    {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}
