import axios from "../../utilities/axios";
import { Event } from "../Event";

export class PersonFkCityIdEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        // alert("yee");
        axios
            .get("/api/v1/matching-region/" + value)
            .then((res) => {
                console.log("data do region",res.data);
                if (res && res.data && res.data.item && res.data.item.region_name) {
                    setField("person_fk_macro_region_id", res.data.item.region_fk_macro_region_id);
                    this.setFieldValue("person_microregion", res.data.item.region_name);
                    this.setFieldValue("person_fk_company_id", res.data.item.company.company_id);
                    //setField("person_fk_company_id", { label: res.data.item.company.company_name, value: res.data.item.company.company_id.toStirng() });
                    
                }
            })
            .catch((e) => {});
    };
}


export class PersonHasShipperEvents extends Event {
    public onChange = (value : boolean) => {
        // console.log("Entrou")
        if(value === false) {
            // console.log("Entrou 2");
            this.setFieldValue('person_shipping_fk_person_id', );
        }
    }
}

export class PersonAcceptIncomeEvents extends Event {
    public onChange = (value : boolean) => {
        if(value == false) {
            this.setFieldValue('person_obs_accept_income',"")
        }
    }
}

export class PersonGetNfseEvents extends Event {
    public onChange = (value : boolean) => {
        if(value === false) {
            this.setFieldValue('person_obs_get_nfse',"")
        }
    }
}

export class PersonFkStateIdEvents extends Event {
    public onChange = (value: any) => {
        var mask: string[] | undefined = undefined;
        switch (value) {
            case "4":
                mask = ["99.999.999/999-99"];
                break;
            case "6":
                mask = ["99.999.999-9"];
                break;
            case "8":
                mask = ["999999-99"];
                break;
            case "9":
                mask = ["99999999-9"];
                break;
            case "10":
                mask = ["99999999999-99"];
                break;
            case "12":
                mask = ["99.999.999-9"];
                break;
            case "14":
                mask = ["999.999.999-9999"];
                break;
            case "16":
                mask = ["9999999999-9"];
                break;
            case "17":
                mask = ["99-999999-9"];
                break;
            case "18":
                mask = ["99999999-9"];
                break;
            case "19":
                mask = ["99.9.999.9999999-9"];
                break;
            case "21":
                mask = ["99999999-99"];
                break;
            case "22":
                mask = ["99.999.99-9"];
                break;
            case "23":
                mask = ["99.999.999-9"];
                break;
            case "24":
                mask = ["999.99999-9"];
                break;
            case "25":
                mask = ["99999999-9"];
                break;
            case "26":
                mask = ["999/9999999"];
                break;
            case "27":
                mask = ["999.999.999"];
                break;
            case "28":
                mask = ["999.999.999.999"];
                break;
            case "29":
                mask = ["999999999999999999"];
                break;
        }
        // this.setFieldProperty("person_state_ins", "mask", mask);
    };
}

export class PersonContactsEvents extends Event {
    public overrideConditions = (value: any, setField: any, setState: any, formValues: any) => {
        var isRequired = true;
        if(formValues["person_type_is_supply"] === true) {
            isRequired = false;
        }
        if(formValues["person_type_is_shipping"] === true) {
            isRequired = false;
        }
        if(formValues["person_type_is_prospect"] === true) {
            isRequired = false;
        }
        return {
            person_contact_sector: {
                required: isRequired,
            },
        };
    };
}

export class Pers extends Event {
    public overrideConditions = (field: any, setField: any, setState: any, formValues: any) => {
        
        var value = formValues[field.name];
        // console.log("OVERRIDE",value);
        // alert(value);
        return {
            person_cnpj_cpf: {
                required: !(value === true),
            },
        };
    };
}

// export class PersonFkCityId extends PersonGenericEvents {}
