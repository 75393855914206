import React from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router";
import { ModuleContext } from "../../components/authProvider/main";
import { Button, CheckPicker, Checkbox, DateRangePicker, Panel } from "rsuite";
import classNames from "classnames";
import { IntlProvider } from 'rsuite';
import ptBr from 'rsuite/lib/IntlProvider/locales/pt_BR';

class SyncPageCustom extends React.Component<any, {}> {
    static contextType = ModuleContext;
    public state: any = {
        syncOptions: [
            { label: 'tipofrete', value: 'tipofrete' },
            { label: 'tipopedvenda', value: 'tipopedvenda' },
            { label: 'condpagamento', value: 'condpagamento' },
            { label: 'item', value: 'item' },
            { label: 'tabelaspreco', value: 'tabelaspreco' },
            { label: 'tabelasprecoitem', value: 'tabelasprecoitem' },
            { label: 'clifor', value: 'clifor' },
            { label: 'cliforendcobranca', value: 'cliforendcobranca' },
            { label: 'cliforendentrega', value: 'cliforendentrega' }
        ]
    };

    render() {
        return (<>
            <IntlProvider locale={ptBr}>
                <div style={{ zIndex: 1, marginLeft: localStorage.getItem("hiddenSidebar") == "true" ? "0px" : "203px" }} className="container">
                    <Helmet>
                        <title>Fast Order - Sincronização</title>
                    </Helmet>
                    <Panel bordered className="rs-no-body mb-4 w-100">
                        <div className="d-flex justify-contents-between align-items-center">
                            <div></div>
                            <div>
                                <h5 className="d-flex align-items-center">
                                    <i className={classNames("fas", "fa-fw mr-4", "fa-bezier-curve")}></i>
                                    SINCRONIZAÇÃO
                                </h5>
                            </div>
                            <div>
                            </div>
                        </div>
                    </Panel>
                    <Panel bordered className="rs-no-body mb-4 w-100">
                        {/* <div style={{ height: "120px", width: "100%", flexDirection: "row", display: "flex" }}> */}
                        <DateRangePicker format="DD/MM/YYYY" />
                        <CheckPicker placeholder="Selecione os dados a serem sincronizados" data={this.state.syncOptions || []} style={{ width: 600 }} />
                        {/* </div> */}
                        <Button style={{ marginLeft: 15 }} appearance="primary" color="blue"><i className={classNames("fas", "fa-fw mr-4", "fa-sync-alt")}></i>Sincronizar</Button>
                    </Panel>
                </div>
            </IntlProvider>
        </>)
    }
}

export default withRouter(SyncPageCustom);