import classNames from "classnames";
import React from "react";
import { ContentState, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
// import "draft-js/lib/";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Grid, Row, Col } from 'rsuite';



export class WYSIWYG extends React.Component<any, {}> {
    // public contentTest : any = convertFromHTML(this.props.value)
    // public initialContent = EditorState.createWithContent(ContentState.createFromText('abc'));
    public state = {
        value: this.props.value ?? "",
        editorState: EditorState.createEmpty(),
        focused: false,
    };

    onContentChange = (value: any) => {
        var html = draftToHtml(value);
        this.setState({ value: html });
        if (this.props.onChange) {
            this.props.onChange(html);
        }
    };

    componentDidMount() {
        if ((this.props.defaultValue && this.props.defaultValue.length > 0) || (this.props.value && this.props.value.length > 0)) {
            const value = this.props.value ?? this.props.defaultValue;
            const contentBlock = htmlToDraft(value);
            this.setState({
                editorState: EditorState.createWithContent(ContentState.createFromBlockArray(contentBlock.contentBlocks, contentBlock.entityMap)),
            });
        }
    }

    // shouldComponentUpdate(nextProps: any, nextState: any) {
    //     if (this.state.value !== nextState.value) {
    //         return true;
    //     }
    //     return false;
    // }

    onChange = (value: any) => {
        // console.log({value});
        this.setState({ editorState: value });
    };

    onFocus = () => {
        this.setState({ focused: true });
    };

    onBlur = () => {
        this.setState({ focused: false });
    };

    render() {
        return (
            <div className={this.props.className} style={this.props.style}>
                <div className={classNames(this.props.children ? "no-second-toolbar" : "", this.state.focused ? "rdw-focused" : "")}>
                    <div id="editorContainer">
                    <Editor
                        onFocus={this.onFocus}
                        readOnly={this.props.readOnly}
                        onBlur={this.onBlur}
                        toolbar={{ options: ["inline", "list", "textAlign", "link", "history"] }}
                        editorState={this.state.editorState}
                        onContentStateChange={this.onContentChange}
                        onEditorStateChange={(val: any) => this.onChange(val)}
                    />
                    </div>
                    {/* {this.props.value} */}
                </div>
            </div>
        );
    }
}
