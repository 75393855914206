import React, { Component, useEffect, useState } from "react";
import { Table, Button, Input, Modal, Whisper, Tooltip, IconButton, Icon, Col } from "rsuite";
import { ModalErrors } from './ModalErrors';
import { AxiosSelectPicker } from "../formfields/AxiosSelectPicker";
import axios from "../../utilities/axios";
import { CellColor } from "./CellColor"

const { Column, HeaderCell, Cell, Pagination, ColumnGroup } = Table;


interface Props {
    onChangeFunction?: any;
    xmlData?: [];
    onLoading: boolean;
    onSelectNcm: (type: 'selectIsXml' | 'selectIsOrder' | 'selectAlter' | 'cancelNote', firstNameColumn: string, rowData: any, xmlId: number | string) => void;
    onRevalid: (indexProd: any,productId: number | string) => void
}

interface State {
    showModal: boolean;
    modalData: any;
    expandedRowKeys: [];
    typeStateNcm: '';
    indexColor: null,
    dataTeste: [{}];
}
const rowKey = 'xml_id';

const ExpandCell = ({ rowData, dataKey, expandedRowKeys, onChange, ...props }: any) => (
    <Cell {...props}>
        <IconButton
            size="xs"
            appearance="subtle"
            onClick={() => {
                onChange(rowData);
            }}
            icon={
                <Icon
                    icon={
                        expandedRowKeys.some((key: any) => key === rowData[rowKey])
                            ? 'minus-square-o'
                            : 'plus-square-o'
                    }
                />
            }
        />
    </Cell>
)
export class TableRoles extends Component<Props> {
    constructor(props: any) {
        super(props)

        this.handleExpanded = this.handleExpanded.bind(this);
    }

    public state: State = {
        showModal: false,
        modalData: [],
        expandedRowKeys: [],
        typeStateNcm: '',
        indexColor: null,
        dataTeste: [
            {
                "xml_id": "1228cbb3-eb65-4e05-a6f7-1c31b8aab294",
                "xml_url": "/storage/uploads/10-10-2023/1696954728_35230920351463000105550010000144891041353102.xml",
                "xPed_origin": "22080",
                "name_xml_origin": "NFe35230920351463000105550010000144891041353102",
                "dest_CNPJ_origin": "17737816000103",
                "dest_CNPJ_validate": "17737816000103",
                "dest_IE_origin": "535326495118",
                "dest_IE_validate": "535.326.495.118",
                "emit_CNPJ_origin": "20351463000105",
                "emit_CNPJ_validate": "20351463000106",
                "emit_IE_validate": "535530302117",
                "enderEmit_CEP_origin": "13425380",
                "enderEmit_CEP_validate": "13425-380",
                "enderDest_CEP_origin": "13413013",
                "enderDest_CEP_validate": "13413013",
                "enderDest_number_origin": "280",
                "enderDest_number_alter": "280",
                "transp_modFrete_origin": "FOB",
                "transp_modFrete_validate": "CIF",
                "nDup_origin": "001",
                "nDup_validate": "F35",
                "validate": false,
                "prod": [
                    {
                        "xml_id": "1228cbb3-eb65-4e05-a6f7-1c31b8aab294",
                        "nItem": "1",
                        "CFOP_origin": "5102",
                        "CFOP_validate": "5102",
                        "article_group_validate": "COMPONENTE",
                        "id_validate": "1002",
                        "id_origin": "",
                        "NCM_origin": "73181500",
                        "NCM_validate": "73201599",
                        "NCM_alter": "",
                        "cProd_origin": "19073",
                        "cProd_alter": "",
                        "uTrib_origin": "CT",
                        "uTrib_validate": "Pc",
                        "uTrib_alter": "",
                        "qTrib_origin": "0.24",
                        "qTrib_validate": "2,000000",
                        "qTrib_alter": 0.24,
                        "vIcms_origin": "25.06",
                        "vIPI_origin": 0,
                        "CFOP_valid": false,
                        "vIcms_validate": 25.06,
                        "vIPI_validate": 0,
                        "NCM_warning": false,
                        "cProd_valid": false
                    },
                    {
                        "xml_id": "1228cbb3-eb65-4e05-a6f7-1c31b8aab294",
                        "nItem": "2",
                        "CFOP_origin": "5102",
                        "CFOP_validate": "5102",
                        "article_group_validate": "COMPONENTE",
                        "id_validate": "1002",
                        "id_origin": "",
                        "NCM_origin": "73181500",
                        "NCM_validate": "73201500",
                        "NCM_alter": "",
                        "cProd_origin": "6406",
                        "cProd_alter": "14667",
                        "uTrib_origin": "CT",
                        "uTrib_validate": "Pc",
                        "uTrib_alter": "",
                        "qTrib_origin": "0.02",
                        "qTrib_validate": "24,000000",
                        "qTrib_alter": 0.02,
                        "vIcms_origin": "0.45",
                        "vIPI_origin": 0,
                        "CFOP_valid": false,
                        "vIcms_validate": 0.45,
                        "vIPI_validate": 0,
                        "NCM_warning": false
                    }
                ],
                "errors": [
                    {
                        "nameColumn": "Numero xPed",
                        "valueError": "Sem numero xPed no XML!"
                    },
                    {
                        "nameColumn": "Conta contabil",
                        "valueError": "Existe Conta contabil no pedido de origem!"
                    },
                    {
                        "nameColumn": "CNPJ Emitente",
                        "valueError": "O CNPJ é diferente do pedido"
                    },
                    {
                        "nameColumn": "CFOP Divergentes",
                        "valueError": "Não possui nenhuma regra para o CFOP: 5102, no validador"
                    },
                    {
                        "nameColumn": "CFOP Divergentes",
                        "valueError": "Não possui nenhuma regra para o CFOP: 5102, no validador"
                    },
                    {
                        "nameColumn": "Frete Divergente",
                        "valueError": "O frete presente no XML é diferente do Pedido"
                    }
                ],
                "emit_CNPJ_valid": false,
                "transp_modFrete_valid": false
            }
        ]
    }

    setModalData = (rowData: any, index: number) => {
        this.setState({
            showModal: true,
            modalData: { rowData, index }
        })
    }

    colorColumns = () => {
        const output: any = [];
        const formValues: any = this.props.xmlData;
        for (const i in formValues) {
            // console.log(formValues[i]);
        }

        return '#E06C75'
    }

    onSelectisAxaptaCode = (field: string, value: string, indexProd: any, rowDataGridId: any) => {
        axios
            .get('/api/v1/products/' + value)
            .then((res) => {
                this.props.onChangeFunction(field, res.data.item.product_code, indexProd, rowDataGridId, true,value)
            })
            .catch((error) => {
                console.log(error.response)
            })
            .finally(() => {
                this.props.onRevalid(rowDataGridId,value)
            })
    }

    handleExpanded(rowData: any, _dataKey: any) {
        const { expandedRowKeys } = this.state;
        let open = false;
        const nextExpandedRowKeys = [];

        expandedRowKeys.forEach(key => {
            if (key === rowData[rowKey]) {
                open = true;
            } else {
                nextExpandedRowKeys.push(key);
            }
        });

        if (!open) {
            nextExpandedRowKeys.push(rowData[rowKey]);
        }
        this.setState({
            expandedRowKeys: nextExpandedRowKeys
        });
    }

    isTypeNcm = (type: 'selectIsXml' | 'selectIsOrder' | 'selectAlter' | 'cancelNote', firstNameColumn: string, rowData: any, xmlId: number | string,index: string | number) => {
        this.setState({typeStateNcm: type, indexColor: index})
        switch (firstNameColumn) {
            case 'NCM':
                rowData.typeNcm = type;
            break;
            case 'id':
                rowData.typeIdOrder = type;
            break;

        }
        this.props.onSelectNcm(type, firstNameColumn, rowData, xmlId)
    }


    renderTableExpandle = (rowDataGrid: any) => {
        return (<>
            <Table
                bordered
                cellBordered
                headerHeight={90}
                rowHeight={60}
                height={600}
                loading={this.props.onLoading}
                data={rowDataGrid.prod}
                // width={1500}
                style={{ width: '80vw' }}
            >
                <Column width={80} align="center" fixed>
                    <HeaderCell>Nº Item</HeaderCell>
                    <Cell dataKey="nItem" />
                </Column>
                <ColumnGroup header="NCM" align="center">
                    <Column width={100} align="center" resizable>
                        <HeaderCell>XML</HeaderCell>
                        <CellColor  stateObserve={this.state.typeStateNcm} indexKey={this.state.indexColor} dataKey="NCM_origin" />
                    </Column>
                    <Column width={100} align="center" >
                        <HeaderCell>Pedido</HeaderCell>
                        <CellColor stateObserve={this.state.typeStateNcm} indexKey={this.state.indexColor} dataKey="NCM_validate" />
                    </Column>
                    <Column width={160} align="center" >
                        <HeaderCell>Ações</HeaderCell>
                        <Cell>
                            {(rowData: any, index: any) => {
                                // if (rowData?.validate == true) {
                                return (<div style={{ justifyContent: 'space-between', display: 'flex' }}>
                                    <Whisper placement="top" trigger="hover" speaker={<Tooltip>Manter NCM do XML</Tooltip>}>
                                        <Button color="green" onClick={(e) => this.isTypeNcm("selectIsXml", 'NCM', rowData, rowData['xml_id'],index)}>
                                            <i className="fas fa-check"></i>
                                        </Button>
                                    </Whisper>
                                    <Whisper placement="top" trigger="hover" speaker={<Tooltip>Manter NCM do Pedido</Tooltip>}>
                                        <Button color="green" onClick={(e) => this.isTypeNcm('selectIsOrder', 'NCM', rowData, rowData['xml_id'],index)}>
                                            <i className="fas fa-check"></i>
                                        </Button>
                                    </Whisper>
                                    <Whisper placement="top" trigger="hover" speaker={<Tooltip>Cancelar Nota</Tooltip>}>
                                        <Button color="red" onClick={(e) => this.isTypeNcm('cancelNote', 'NCM', rowData, rowData['xml_id'],index)}>
                                            <i className="fas fa-ban" ></i>
                                        </Button>
                                    </Whisper>
                                </div>)
                                // }
                            }}
                        </Cell>
                    </Column>
                </ColumnGroup>
                <ColumnGroup header="Código do Produto" align="center">
                    <Column align="center" >
                        <HeaderCell>XML</HeaderCell>
                        <Cell dataKey="cProd_origin" />
                    </Column>
                    <Column width={220} align="center" >
                        <HeaderCell>Axapta</HeaderCell>
                        <Cell>
                            {(rowData: any, index: number) => {
                                if (rowData.cProd_valid === false) {
                                    return (<div style={{ width: '90%' }}>
                                        <AxiosSelectPicker
                                            data={[]}
                                            api={{
                                                methd: 'GET',
                                                url: '/api/v1/products/select'
                                            }}
                                            advanced={true}
                                            advancedInclude={true}
                                            onChange={(value: string) => this.onSelectisAxaptaCode('cProd_alter', value, index, rowDataGrid.xml_id)}
                                        ></AxiosSelectPicker>
                                        {/* <Input style={{ width: '70px' }} onChange={(event) => this.props.onChangeFunction('cProd_alter', event, index, rowDataGrid.xml_id)} /> */}
                                    </div>)
                                } else {
                                    return (<>
                                        {rowData.cProd_alter}
                                    </>)
                                }
                            }}
                        </Cell>
                    </Column>
                </ColumnGroup>
                <ColumnGroup header="Grupo de Artigo x CFOP x ID" align="center">
                    <Column width={140} align="center" >
                        <HeaderCell>Axapta - GP</HeaderCell>
                        <CellColor dataKey="article_group_validate" />
                    </Column>
                    <Column align="center" >
                        <HeaderCell>XML - CFOP</HeaderCell>
                        <CellColor dataKey="CFOP_validate" />
                    </Column>
                    <Column align="center" >
                        <HeaderCell>Pedido - ID</HeaderCell>
                        <CellColor stateObserve={this.state.typeStateNcm} indexKey={this.state.indexColor} dataKey="id_validate" />
                    </Column>
                    <Column align="center" >
                        <HeaderCell>Axapta - ID</HeaderCell>
                        <CellColor stateObserve={this.state.typeStateNcm} indexKey={this.state.indexColor} dataKey="id_alter" />
                    </Column>
                    <Column width={120} align="center" >
                        <HeaderCell>Ações</HeaderCell>
                        <Cell>
                            {(rowData: any, index: any) => {
                                if (rowData?.id_valid == false) {
                                    return (<div style={{ justifyContent: 'space-around', display: 'flex' }}>
                                        <Whisper placement="top" trigger="hover" speaker={<Tooltip>Alterar ID do Pedido</Tooltip>}>
                                            <Button color="green" onClick={(e) => this.isTypeNcm("selectAlter", 'id', rowData, rowData['xml_id'],index)}>
                                                <i className="fas fa-check"></i>
                                            </Button>
                                        </Whisper>
                                        <Whisper placement="top" trigger="hover" speaker={<Tooltip>Cancelar Nota</Tooltip>}>
                                            <Button color="red" onClick={(e) => this.isTypeNcm('cancelNote', rowData, 'id', rowData['xml_id'],index)}>
                                                <i className="fas fa-ban" ></i>
                                            </Button>
                                        </Whisper>
                                    </div>)
                                }
                            }}
                        </Cell>
                    </Column>
                </ColumnGroup>
                <ColumnGroup header="Unidade de Medida" align="center">
                    <Column width={70} align="center" >
                        <HeaderCell>XML</HeaderCell>
                        <CellColor dataKey="uTrib_origin" />
                    </Column>
                    <Column width={70} align="center" >
                        <HeaderCell>Pedido</HeaderCell>
                        <CellColor dataKey="uTrib_validate" />
                    </Column>
                    <Column width={70} align="center" >
                        <HeaderCell>XML</HeaderCell>
                        <CellColor dataKey="qTrib_origin" />
                    </Column>
                    <Column width={90} align="center" >
                        <HeaderCell>Pedido</HeaderCell>
                        <CellColor dataKey="qTrib_validate" />
                    </Column>
                    <Column width={100} align="center" >
                        <HeaderCell>Qt. Retorno</HeaderCell>
                        <CellColor dataKey="qTrib_alter" />
                    </Column>
                </ColumnGroup>
                <ColumnGroup header="Valor" align="center">
                    <Column width={70} align="center" >
                        <HeaderCell>XML</HeaderCell>
                        <CellColor dataKey="vUnTrib_origin" />
                    </Column>
                    <Column width={70} align="center" >
                        <HeaderCell>Pedido</HeaderCell>
                        <CellColor dataKey="vUnTrib_validate" />
                    </Column>
                </ColumnGroup>
                <ColumnGroup header="Impostos" align="center">
                    <Column align="center" >
                        <HeaderCell>XML - ICMS</HeaderCell>
                        <CellColor dataKey="vIcms_origin" />
                    </Column>
                    <Column align="center" >
                        <HeaderCell>Pedido -ICMS</HeaderCell>
                        <CellColor dataKey="vIcms_validate" />
                    </Column>
                    <Column align="center" >
                        <HeaderCell>XML - IPI</HeaderCell>
                        <CellColor dataKey="vIPI_origin" />
                    </Column>
                    <Column align="center" >
                        <HeaderCell>Pedido - IPI</HeaderCell>
                        <CellColor dataKey="vIPI_validate" />
                    </Column>
                </ColumnGroup>
            </Table>
        </>)
    }


    render() {
        const { expandedRowKeys }: any = this.state;

        return (<>
            <ModalErrors
                showModal={this.state.showModal}
                modalData={this.state.modalData}
                funcaoclose={() => {
                    this.setState({ showModal: false });
                }}
            />
            <Table
                data={this.props.xmlData}
                loading={this.props.onLoading}
                bordered
                cellBordered
                hover
                rowKey={rowKey}
                expandedRowKeys={this.state.expandedRowKeys}
                height={700}
                headerHeight={90}
                rowExpandedHeight={600}
                rowHeight={60}
                renderRowExpanded={rowData => {
                    return this.renderTableExpandle(rowData);
                }}
            >
                <Column width={80} align="center" fixed>
                    <HeaderCell>#</HeaderCell>
                    <ExpandCell
                        dataKey="xml_id"
                        expandedRowKeys={expandedRowKeys}
                        onChange={this.handleExpanded}
                    />
                </Column>
                <Column width={120} align="center">
                    <HeaderCell>Validado</HeaderCell>
                    <Cell>
                        {(rowData: any) => {
                            if (rowData?.validate == false) {
                                return (<div >
                                    <i className="fas fa-exclamation-circle" style={{color: '#E06C75'}}></i>
                                </div>)
                            } else {
                                return (<div>
                                    <i className="fas fa-check-circle"  style={{color: '#a0d995'}}></i>
                                </div>)
                            }
                        }}
                    </Cell>
                </Column>
                <Column width={120} align="center">
                    <HeaderCell>Nº xPed</HeaderCell>
                    <Cell>
                        {(rowData: any, index: number) => {
                            if (rowData.xPed_origin == '') {
                                return (<>
                                    <Input style={{ width: '70px' }} onChange={(event) => this.props.onChangeFunction('xPed_origin', event, index)} />
                                </>)
                            } else {
                                return (<>
                                    {rowData.xPed_origin}
                                </>)
                            }
                        }}
                    </Cell>
                </Column>
                <Column width={500} resizable>
                    <HeaderCell>Nome do Arquivo</HeaderCell>
                    <Cell dataKey="name_xml_origin" />
                </Column>
                <Column width={200} resizable>
                    <HeaderCell>CNPJ Emitente</HeaderCell>
                    <Cell dataKey="emit_CNPJ_origin" />
                </Column>
                <Column width={120} resizable>
                    <HeaderCell>IE Emitente</HeaderCell>
                    <Cell dataKey="emit_IE_validate" />
                </Column>
                <ColumnGroup header="CEP do Emitente" align="center">
                    <Column >
                        <HeaderCell>XML</HeaderCell>
                        <Cell>
                            {(rowData: any) => {
                                let str: string = rowData.enderEmit_CEP_origin;
                                if (str == null) {
                                    return str = "Sem numéro";
                                }
                                return str = str.replace(/^(\d{5})(\d{3})/, "$1-$2")
                            }}
                        </Cell>
                    </Column>
                    <Column >
                        <HeaderCell>Pedido</HeaderCell>
                        <CellColor dataKey="enderEmit_CEP_validate" />
                    </Column>
                </ColumnGroup>
                <ColumnGroup header="CEP do Destinatario" align="center">
                    <Column >
                        <HeaderCell>XML</HeaderCell>
                        <Cell>
                            {(rowData: any) => {
                                let str: string = rowData.enderDest_CEP_origin;
                                if (str == null) {
                                    return str = "Sem numéro";
                                }
                                return str = str.replace(/^(\d{5})(\d{3})/, "$1-$2")
                            }}
                        </Cell>
                    </Column>
                    <Column >
                        <HeaderCell>Pedido</HeaderCell>
                        <CellColor dataKey="enderDest_CEP_validate" />
                    </Column>
                </ColumnGroup>
                <ColumnGroup header="Endereço do Destinatário" align="center">
                    <Column >
                        <HeaderCell>XML</HeaderCell>
                        <Cell dataKey="enderDest_number_origin" />
                    </Column>
                    <Column >
                        <HeaderCell>Pedido</HeaderCell>
                        <CellColor dataKey="enderDest_number_origin" />
                    </Column>
                </ColumnGroup>
                <ColumnGroup header="Frete" align="center">
                    <Column align="center" >
                        <HeaderCell>XML</HeaderCell>
                        <CellColor dataKey="transp_modFrete_origin" />
                    </Column>
                    <Column align="center" >
                        <HeaderCell>Pedido</HeaderCell>
                        <CellColor dataKey="transp_modFrete_validate" />
                    </Column>
                </ColumnGroup>
                <ColumnGroup header="Duplicatas" align="center">
                    <Column align="center" >
                        <HeaderCell>XML</HeaderCell>
                        <CellColor dataKey="nDup_origin" />
                    </Column>
                    <Column align="center" >
                        <HeaderCell>Pedido</HeaderCell>
                        <CellColor dataKey="nDup_validate" />
                    </Column>
                </ColumnGroup>
                <Column align="center" flexGrow={2}>
                    <HeaderCell>Ações</HeaderCell>
                    <Cell>
                        {(rowData: any, index: any) => {
                            return (<>
                                <Button onClick={(e) => this.setModalData(rowData, index)}>
                                    <i className="fas fa-eye"></i>
                                </Button>
                            </>)
                        }}
                    </Cell>
                </Column>
            </Table>
        </>)
    }
}