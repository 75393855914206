import React, { Component } from "react";
import { Button, Notification } from "rsuite";
import axios from "../../../utilities/axios";

export class CustomImport extends Component<any, {}> {
    public state = {
        id: this.props.values.mbm_credential_id,
        loading: false
    }
    onClickButton = () => {
        if (this.props.values.mbm_credential_id) {
            this.setState({ loading: true })
            axios.get("/api/v1/importa-empresas-mbm-credential/" + this.props.values.mbm_credential_id).then((res) => {
                window.location.reload()
            }).catch((e) => {
                return Notification.error({ title: "Error", description: "Algo deu Errado!" })
            }).finally(() => {
                this.setState({ loading: false })
            })
        } else {
            return Notification.error({ title: "Error", description: "Erro ao identificar registro!" })
        }
    }

    onClickButtonImportAll = () => {
        if (this.props.values.mbm_credential_id) {
            this.setState({ loading: true })
            axios.get("/importadadosmbm/" + this.props.values.mbm_credential_id).then((res) => {
                window.location.reload()
            }).catch((e) => {
                return Notification.error({ title: "Error", description: "Algo deu Errado!" })
            }).finally(() => {
                this.setState({ loading: false })
            })
        } else {
            return Notification.error({ title: "Error", description: "Erro ao identificar registro!" })
        }
    }
    render() {
        return (<>
            <Button onClick={() => this.onClickButton()} loading={this.state.loading} appearance="primary">Importar Empresas</Button>
            <Button style={{marginTop:15}} onClick={() => this.onClickButtonImportAll()} loading={this.state.loading} appearance="primary" color="red">Importar Banco inteiro</Button>
        </>)
    }
}