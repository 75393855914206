import React, { Component } from "react";
import { Modal, Table, Button } from "rsuite";

const { Column, HeaderCell, Cell, Pagination } = Table;


interface Props {
    showModal: boolean;
    modalData: any;
    funcaoclose: any;
}

export class ModalErrors extends Component<Props> {
    renderModal = () => {
        const { index, rowData } = this.props.modalData
        return (<>
            <Modal show={this.props.showModal} size="lg" onHide={this.props.funcaoclose}>
                <Modal.Header>
                    <b> Logs de Erros </b>
                </Modal.Header>
                <Modal.Body>
                    {rowData !== undefined && (<>
                        <Table
                            wordWrap
                            data={rowData.errors}
                            height={450}
                        >
                            <Column width={200} resizable align="left" >
                                <HeaderCell>Motivo</HeaderCell>
                                <Cell dataKey="nameColumn" />
                            </Column>
                            <Column width={350} resizable align="left" >
                                <HeaderCell>Descrição do Erro</HeaderCell>
                                <Cell dataKey="valueError" />
                            </Column>
                        </Table>
                    </>)}
                    {rowData === undefined && (
                        <h1>
                            Não Existem Erros para esse pedido
                        </h1>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button color="red" onClick={this.props.funcaoclose}>Fechar</Button>
                </Modal.Footer>
            </Modal>

        </>)
    }

    render() {
        return (<>
           {this.renderModal()}
        </>)
    }
}