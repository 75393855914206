import { Component } from "react";
import { Table, Button, Notification, Row, Col, Input, Content, ControlLabel, Panel, DatePicker, Checkbox } from "rsuite";
import axios from "../../../utilities/axios";
import dayjs from "dayjs";
import JSZip from "jszip";
import { AxiosSelectPicker } from "../AxiosSelectPicker";
import _ from "lodash";

const { Column, HeaderCell, Cell, Pagination, ColumnGroup } = Table;


const alertMessage: any = Notification
export class TableNewDownloader extends Component {

    public state: any = {
        filters: {
            update_file_created_at_start: {
                "field": "update_file_created_at",
                "op": "gte",
                "value": dayjs().subtract(30, 'days').format('YYYY-MM-DD HH:mm:ss.sss')
                
            },
            update_file_created_at_end: {
                "field": "update_file_created_at",
                "op": "lte",
                "value": dayjs().format('YYYY-MM-DD HH:mm:ss.sss')
            },
        },
        dateIni: dayjs().subtract(30, 'days').format('MM/DD/YYYY'),
        endDate: dayjs().format('MM/DD/YYYY'),
        isLoading: false,
        data: [],
        check: [],
        checked: []
    }
    onChangeFilters = (field: any, op: any, value: any, alias?: any) => {
        this.setState({ filters: { ...this.state.filters, [alias !== undefined ? alias : field]: { field: field, op: op, value: value } } })
    }

    filterXmls = () => {
        this.setState({ isLoading: true })
        axios
            .get('/api/v1/validate/filter-list', {
                params: { params: { ...this.state.filters } }
            })
            .then((res) => {
                alertMessage[res.data.type]({ title: res.data.type == "error" ? "Erro!" : "Sucesso!", description: res.data.text })
                this.setState({ data: res.data.data })
            })
            .catch((e) => {
                alertMessage[e.response.data.type]({ title: e.response.data.type == "error" ? "Erro!" : "Sucesso!", description: e.response.data.text })
                console.log(e)
            })
            .finally(() => {
                this.setState({ isLoading: false })
            })
    }

    handleCheck = (rowData: any) => {
        var checked = this.state.checked;
        var index = checked.indexOf(rowData);
        if (index > -1) {
            checked.splice(index, 1);
        } else {
            checked.push(rowData);
        }
        this.setState({ checked: checked });
    }

    dowloadItem = (rowData: any) => {
        const url = rowData.update_file_path;
        axios
            .get(`http://${window.location.host}${url}`, {
                responseType: "blob",
            })
            .then((res) => {
                const blobUrl = window.URL.createObjectURL(new Blob([res.data]));
                const fileName = rowData.update_file_path
                const aTag = document.createElement("a");
                aTag.href = blobUrl;
                aTag.setAttribute("download", fileName);
                document.body.appendChild(aTag);
                aTag.click();
                aTag.remove();
            })
            .catch((e) => {
                // console.log('meu Erro:::> ',e)
                Notification.error({
                    title: "Error!!",
                    description: e.response,
                });
            });
    };

    dowloadAllFiles = async (type: 'all' | 'checkeds') => {
        const zip = new JSZip();
        const filesData = type == 'all' ? this.state.data : this.state.checked
        const filesNames: any = filesData
        const filesFolder: any = zip.folder('XMLs')
        for(const item  of filesNames){
            const url = item.update_file_path
            await axios
            .get(`http://${window.location.host}${url}`, {
                responseType: "blob",
            })
            .then(async (res) => {
                const fileName = item.update_file_path.split("/").pop().split('.xml').shift();
                filesFolder.file(`${fileName}.xml`, res.data)
            })
            .catch((e) => {
                Notification.error({
                    title: "Error!!",
                    description: e,
                });
            });
        }

        let folderName = filesData[0].update_file_path.split("/storage/returns/").pop().split('/').shift()
        folderName = `${folderName}-${dayjs().unix()}`
        const compressed: any = await zip.generateAsync({ type: 'blob' }).then((content) => {
            // console.log({ content });
            return content;
        }).catch((e) => {
            console.log(e)
        })

        const blobUrl = window.URL.createObjectURL(new Blob([compressed]));
        const fileNameZip = `${folderName}.zip`
        const aTag = document.createElement("a");
        aTag.href = blobUrl;
        aTag.setAttribute("download", fileNameZip);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
    }

    // dowloadSelectItens = async () => {
    //     console.log('Check :::> ', this.state.checked);
    // }
    render() {
        return (<>
            <Panel
                bordered
            >

                <Content>
                    <Row style={{ display: 'flex' }}>
                        <Col style={{ display: 'flex', flexDirection: 'row', width: '70%' }}>
                            <Col style={{ width: '13vw' }}>
                                <ControlLabel>
                                    Data de Inicio
                                </ControlLabel>
                                <DatePicker
                                    defaultValue={this.state.dateIni}
                                    format="DD/MM/YYYY"
                                    onChange={(value: any) => this.onChangeFilters('update_file_created_at', 'gte', dayjs(value).format('YYYY-MM-DD HH:mm:ss.sss'), 'update_file_created_at_start')}
                                />
                            </Col>
                            <Col style={{ width: '13vw', marginLeft: '15px' }}>
                                <ControlLabel>
                                    Data Fim
                                </ControlLabel>
                                <DatePicker
                                    defaultValue={this.state.endDate}
                                    format="DD/MM/YYYY"
                                    onChange={(value: any) => this.onChangeFilters('update_file_created_at', 'lte', dayjs(value).format('YYYY-MM-DD HH:mm:ss.sss'), 'update_file_created_at_end')}
                                />
                            </Col>
                            <Col style={{ width: '20vw', marginLeft: '15px' }}>
                                <ControlLabel>
                                    Chave da NFe
                                </ControlLabel>
                                <AxiosSelectPicker
                                    data={[]}
                                    api={{
                                        methd: 'GET',
                                        url: 'api/v1/update_files/select'
                                    }}
                                    // displayLabel='update_file_key_xml'

                                    onChange={(value: any,data:any) => this.onChangeFilters('update_file_key_xml', 'where', _.find(data, { 'value' : value})?.label ?? '')}
                                ></AxiosSelectPicker>
                            </Col>
                            <Col style={{ width: '20vw', marginLeft: '15px' }}>
                                <ControlLabel>
                                    CNPJ Emissor
                                </ControlLabel>
                                <AxiosSelectPicker
                                    data={[]}
                                    api={{
                                        methd: 'GET',
                                        url: 'api/v1/suppliers/select'
                                    }}
                                    displayLabel='supplier_cpfcnpj'

                                    onChange={(value: any) => this.onChangeFilters('update_file_fk_supplier_id', 'where', value)}
                                ></AxiosSelectPicker>
                            </Col>

                        </Col>
                        <Col style={{ display: 'flex', width: '30%', justifyContent: 'end' }}>
                            <Col
                                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'end', marginBottom: '2px' }}
                            >
                                <Button loading={this.state.isLoading} size="lg" color="violet" onClick={(e) => this.filterXmls()}>Consular XML's de retorno</Button>
                            </Col>
                        </Col>
                    </Row>
                    <Row>
                        <Table
                            loading={this.state.isLoading}
                            data={this.state.data}
                            renderEmpty={() => <div className="rs-table-body-info">Nenhum item encontrado.</div>}
                            rowHeight={60} height={600}
                        >
                            <Column>
                                <HeaderCell>
                                    #
                                </HeaderCell>

                                <Cell>
                                    {(rowData: any) => {
                                        return (
                                            <Checkbox
                                                value={rowData['update_file_id']}
                                                onChange={() => this.handleCheck(rowData)}
                                                // checked={this.state.checkedKeys.some((item: any) => item === rowData['update_file_id'])}
                                                defaultChecked={this.state.checked.includes(rowData['update_file_id'], rowData)}
                                            />
                                        )
                                    }}
                                </Cell>
                            </Column>
                            <Column width={150} resizable>
                                <HeaderCell>Numero da Nota Fiscal</HeaderCell>
                                <Cell dataKey="update_file_number_nf" />
                            </Column>
                            <Column width={150} resizable>
                                <HeaderCell>Data de Emissão NFe</HeaderCell>
                                <Cell>
                                    {(rowData: any) => {
                                        const dataFormat = dayjs(rowData['update_file_created_at']).format('DD/MM/YYYY')
                                        return(<>
                                            {dataFormat}
                                        </>)
                                    }}
                                </Cell>
                            </Column>
                            <Column width={250} resizable>
                                <HeaderCell>CNPJ Emissor</HeaderCell>
                                <Cell dataKey="update_file_emit_cnpj" />
                            </Column>
                            <Column width={250} resizable>
                                <HeaderCell>Empresa Emissora</HeaderCell>
                                <Cell dataKey="update_file_fk_supplier_id_requisition" />
                            </Column>
                            <Column width={350} resizable>
                                <HeaderCell>Chave da NFe</HeaderCell>
                                <Cell dataKey="update_file_key_xml" />
                            </Column>
                            <Column width={150} resizable>
                                <HeaderCell>Valor da NFe</HeaderCell>
                                <Cell>
                                    {(rowData: any) => {
                                        return(<>
                                            R$ {rowData['update_file_value_nf']}
                                        </>)
                                    }}
                                </Cell>
                            </Column>
                            <Column flexGrow={1} align="center">
                                <HeaderCell>Dowload</HeaderCell>
                                <Cell>
                                    {(rowData: any, index: any) => {
                                        return (
                                            <>
                                                <Button
                                                    onClick={(e) => this.dowloadItem(rowData)}
                                                    color="blue"
                                                    circle
                                                >
                                                    <i className="fas fa-file-download"></i>
                                                </Button>
                                            </>
                                        );
                                    }}
                                </Cell>
                            </Column>
                        </Table>
                        <Content style={{ display: 'flex' }}>
                            <Col>
                                <Button
                                    onClick={() => this.dowloadAllFiles('checkeds')}
                                    color="blue"
                                >
                                    Download dos arquivos selecionados
                                </Button>
                            </Col>
                            <Col style={{ marginLeft: '10px' }}>
                                <Button
                                    onClick={() => this.dowloadAllFiles('all')}
                                    color="blue"
                                >
                                    Download de todos arquivos
                                </Button>
                            </Col>
                        </Content>
                    </Row>
                </Content>
            </Panel>
        </>)
    }
}