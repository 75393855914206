import { Component } from "react";
import { Panel } from "rsuite";
import { MailerModal } from "../../components/mailer/MailerModal";
export class DevRoom extends Component<any, {}> {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <Panel bordered style={{ backgroundColor: "#fff" }} className="rs-no-body d-flex allow-overflow" expanded>
                            <MailerModal template="estimate" data={{
                                nome: 'Jonas'
                            }} isOpen={true} />
                        </Panel>
                    </div>
                </div>
            </div>
        );
    }
}
