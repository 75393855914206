import React from "react";
import config from "../../globals";

export class PrettyError extends React.Component<any, {}> {
    public state: any = {
        error: null,
    };

    parseError = () => {
        if (this.props.error instanceof Blob) {
            let error = this.props.error;
            error.text().then((a) => {
                this.setState({ error: JSON.parse(a) });
                //
            });
            // alert();
        } else {
            this.setState({ error: this.props.error });
            // var error: string = this.props.error.stack;
            // var errorType = error.split(":").shift();
            // var parsed = error
            //     .split((errorType ?? "") + ": ")
            //     .join("")
            //     .split("    at ")
            //     .join("")
            //     .split("\n");
        }
    };

    renderError = () => {
        var stack: any[] = [];
        var error: string = this.state.error.stack;
        if (error === undefined) {
            return (
                <div>
                    <div className="error-title">Não foi possivel conectar-se ao servidor!</div>
                    <div className="error-stack">{stack}</div>
                </div>
            );
        } else {
            var errorType = error.split(":").shift();
            var parsed = error
                .split((errorType ?? "") + ": ")
                .join("")
                .split("    at ")
                .join("")
                .split("\n");
            var mainError = parsed.shift();
            if (this.state.error && this.state.error.html) {
                return (
                    <>
                        <div className="error-title" dangerouslySetInnerHTML={{ __html: this.state.error.html.split("\r\n").join("<br>") }}></div> <br />
                        <div className="error-link">
                            Envie esse link para um desenvolvedor caso você esteja vendo esse erro.
                            <br />
                            <a target={"_blank"} rel="noreferrer" href={config.url + this.state.error.file}>
                                {config.url + this.state.error.file}
                            </a>
                        </div>
                    </>
                );
            }

            if (this.state.error.file) {
                return (
                    <div>
                        <div className="error-title">{mainError}</div>
                        <div className="error-link">
                            Envie esse link para um desenvolvedor caso você esteja vendo esse erro.
                            <br />
                            <a target={"_blank"} rel="noreferrer" href={config.url + this.state.error.file}>
                                {config.url + this.state.error.file}
                            </a>
                        </div>
                    </div>
                );
            } else if (this.state.error.stack) {
                for (var i in parsed) {
                    if (parseInt(i) > 1) {
                        var line = parsed[i];
                        if (line.includes("node_modules")) {
                            continue;
                        }
                        let fileName = line;

                        if (line.includes("(") && line.includes(")")) {
                            var myRegexp = /(\()(.*?)(\))/gm;
                            var match = myRegexp.exec(line);
                            if (match && match[2]) {
                                fileName = match[2];
                            }
                        }
                        let errorLine = fileName.split(".ts:").pop()?.split(".js:").pop()?.split(":").shift();
                        stack.push(
                            <div key={i} className="error-item">
                                <div className="error-item-icon"></div>
                                <div className="error-item-text">{fileName.split(".ts:")?.shift()?.split(".js:")?.shift()}</div>
                                <div className="error-item-line">Linha: {errorLine}</div>
                            </div>,
                        );
                    }
                }
                return (
                    <div>
                        <div className="error-title">{mainError}</div>
                        <div className="error-stack">{stack}</div>
                    </div>
                );
            }
        }
    };

    render() {
        if (this.state.error) {
            return <div className="error">{this.renderError()}</div>;
        }
        this.parseError();
        return null;
    }
}
