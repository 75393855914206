import React from "react";
import { Equalizer } from "../../../utilities/Equalizer";
import { ModuleContext } from "../../authProvider/main";
// import { Widget } from "../Widget";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { Button, FlexboxGrid, List, Tag } from "rsuite";
import axios from "../../../utilities/axios";
import dayjs from "dayjs";
import ClockInModal from "../../clockInModal/ClockInModal";

class ClockInPsm extends React.Component<any, {}> {
    // public widgetElement =
    static contextType = ModuleContext;

    public state = {
        loading: true,
        buttonLoading: false,
        items: [],
        item: null as null | number,
    };

    toggleStatus = (id: any) => {
        this.setState({ buttonLoading: true });
        axios
            .get("/api/v1/clock-in/" + id)
            .then((response) => {
                //alert(response.data.aprovado)
                this.loadData();
                // this.setState({ aprovado: response.data.aprovado });
            })
            .catch((error) => {})
            .finally(() => {
                this.setState({ buttonLoading: false });
            });
    };

    loadData = () => {
        this.setState({ loading: true });
        axios
            .get("/api/v1/clock-in")
            .then((res) => {
                this.setState({ items: res.data.items ?? [], loading: false });
            })
            .finally(() => {
                this.setState({ loading: false });
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    componentDidMount() {
        this.loadData();
    }
    render() {
        return (
            <>
            <div className="widget clock-in-psm">
                <div className="row w-100">
                    <List bordered className="w-100">
                        <List.Item className="header">
                            <FlexboxGrid align="middle">
                                <FlexboxGrid.Item colspan={8}>PSM</FlexboxGrid.Item>
                                {/* <FlexboxGrid.Item colspan={9}>Produto</FlexboxGrid.Item> */}
                                <FlexboxGrid.Item colspan={8}>Data</FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={2}>Status</FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={5}>Ações</FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                        {this.state.items.map((item: any) => (
                            <List.Item>
                                <FlexboxGrid align="middle">
                                    <FlexboxGrid.Item colspan={8}>{item.psm_os_number}</FlexboxGrid.Item>
                                    {/* <FlexboxGrid.Item colspan={9}> */}
                                        {/* <div>
                                            <span>Produto:</span> {item.psm_equipment_product}
                                        </div>
                                        <div>
                                            <span>Serie:</span> {item.psm_equipment_series}
                                        </div>
                                        <div>
                                            <span>Aplicação:</span> {item.psm_equipment_aplication}
                                        </div>
                                        <div>
                                            <span>CCM:</span> {item.psm_equipment_ccm}
                                        </div> */}
                                        {/* {JSON.stringify(item.status)} */}
                                    {/* </FlexboxGrid.Item> */}
                                    <FlexboxGrid.Item colspan={8}>{dayjs(item.status.clock_in_status_created_at ?? undefined).format("DD/MM/YYYY HH:mm")}</FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={2}>
                                        <Tag color={item.status.clock_in_status_type !== "started" ? "red" : "green"}>
                                            {item.status.clock_in_status_type !== "started" ? "Pausado" : "Iniciado"}
                                        </Tag>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item style={{ justifyContent: "flex-end", display: "flex" }} colspan={5}>
                                        <Button className="mr-2" onClick={() => this.setState({item: item.psm_id})} loading={this.state.buttonLoading} color="blue" size={"sm"}>
                                            <i className="fas fa-fw mr-2 fa-list"></i> Histórico
                                        </Button>
                                        {item.status.clock_in_status_type === "stopped" && (
                                            <Button onClick={() => this.toggleStatus(item.psm_id)} loading={this.state.buttonLoading} color="green" size={"sm"}>
                                                <i className="fas fa-fw mr-2 fa-play"></i> Iniciar
                                            </Button>
                                        )}
                                        {item.status.clock_in_status_type === "started" && (
                                            <Button onClick={() => this.toggleStatus(item.psm_id)} loading={this.state.buttonLoading} color="red" size={"sm"}>
                                                <i className="fas fa-fw mr-2 fa-pause"></i> Parar
                                            </Button>
                                        )}
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>
                        ))}
                    </List>
                </div>
            </div>
            <ClockInModal onHide={() => this.setState({item: null})} item={this.state.item} />
            </>
        );
    }
}

export default withRouter(ClockInPsm);
