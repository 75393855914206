import axios from "../../utilities/axios";
import { Event } from "../Event"
import dayjs from "dayjs";
import { Notification } from "rsuite";

export class ItemFkProductIdEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        this.setState('loadings', [
            'item_cod_item', 'item_description', 'item_sale', 'item_unit', 'item_quantity','item_available_stock'
        ])
        // console.log('global:::', this.globalProps?.parentValues?.order_table_price_fk_price_id)
        if (!this.globalProps?.parentValues?.order_table_price_fk_price_id) {
            Notification.warning({
                title: "Atenção !",
                description: "VOCÊ ESTÁ SELECIONANDO UM ITEM SEM ESCOLHER UMA TABELA DE PREÇO!"
            })
         
        }
        axios.get("/api/v1/products/" + value + "?selectTabPreco=" + this.globalProps?.parentValues?.order_table_price_fk_price_id).then((res) => {
            let item = res?.data?.item
            if (res?.data?.item?.selectTabPreco) {
                if (item?.selectTabPreco?.mbm_price_factor == "F") {
                    if (item?.tablePrice?.price_factor == "PV" || !item?.product_sale_standard_price || item?.tablePrice?.price_factor == "" || !item?.tablePrice?.price_factor) {
                        setField("item_unitary_value", ((item?.product_sale_price) * item?.selectTabPreco?.mbm_price_seller_price));
                    } else {
                        setField("item_unitary_value", ((item?.product_sale_standard_price) * item?.selectTabPreco?.mbm_price_seller_price));
                    }
                } else {
                    setField("item_unitary_value", item?.selectTabPreco?.mbm_price_seller_price);
                }
            } else {
                setField("item_unitary_value", item?.product_sale_price);
                Notification.info({
                    title: "Atenção !",
                    description: `Item não encontrado na Tabela de Preço!`
                })
            }
            setField("item_cod_item", (item?.product_code).trim());
            setField("item_description", item?.product_name);
            setField("item_margin", item?.product_margin);
            setField("item_cost_value", item?.product_price_reposition);
            

            setField("item_unit", item?.product_unit);
            setField("item_available_stock", item?.stock);
            setField("item_quantity", 1);
            setField("item_total_value", this.globalProps?.values?.item_unitary_value);

        }).finally(() => {
            this.setState('loadings', [])
        })
    }
}
