import React from "react";
import { Route, withRouter } from "react-router";
import { onStatusResponse } from "../../utilities/errorHandler";
import { AuthContext } from "../authProvider/main";
// import { SearchBar } from "../search-bar/main";

class ProtectedRoute extends React.Component<any, any> {
    static contextType = AuthContext;

    componentDidMount = () => {
        // 
        if (this.context.isAuthenticated !== true) {
            // return this.props.component;
            this.props.history.replace('/login')
        }

        onStatusResponse('404',() => {
            const modulo = window.location.pathname.split('/')[2];
            if(modulo) {
                this.props.history.replace('/dashboard/'+modulo+'/404');
            } else {
                this.props.history.replace('/dashboard/configuracoes/404');
            }
        })
    };

    render() {
        if (this.context.isAuthenticated !== true || (this.props.location.pathname != "/dashboard" && !localStorage.getItem("currentCompany"))) {
            return null;
        }
        // console.log(this.props)
        // console.log(this.props.location.pathname != "/dashboard" && !localStorage.getItem("currentCompany"))
        // 
        return <Route {...this.props}></Route>;
    }
}

export default withRouter(ProtectedRoute);
