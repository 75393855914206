import axios from "../../utilities/axios";
import { Event } from "../Event";

export class UserFkClientIdEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        if (value) {
            axios.get('/api/v1/clients/' + value).then((res: any) => {
                if (res.data.item) {
                    let client = res.data.item
                    this.setFieldValue('user_name', client?.client_name ?? client?.client_fantasy_name)
                    this.setFieldValue('user_code_mbm_user', (client?.client_cliforcode ?? "") + "")
                    this.setFieldValue('user_code_representative', client?.client_represent)
                    this.setFieldValue('user_cpf_cnpj', client?.client_cpf_cnpj)
                    this.setFieldValue('user_email', client?.client_email ?? null)
                }
            })
        }
    }
}