export const pagesConfig = {
    custom: ["404", "parameters", "dev-room", "profile"],
    mappings: {
        dashboard: {
            name: "Dashboard",
            to: "/dashboard/:module",
        },
        parameters: {
            name: "Parametros",
        },
        configuracoes: {
            name: "Configurações",
            to: "/dashboard/configuracoes",
            key: ":module",
            // visible: false,
        },
        main: {
            name: "Principal",
            to: "/dashboard/main",
            key: ":module",
            // visible: false,
        },
        comercial: {
            name: "Comercial",
            to: "/dashboard/comercial",
            key: ":module",
        },
        "compras-estoque": {
            name: "Compras, Estoque e Recebimento",
            to: "/dashboard/compras-estoque",
            key: ":module",
        },
        "assistencia-tecnica": {
            name: "Assistencia Tec.",
            to: "/dashboard/assistencia-tecnica",
            key: ":module",
        },
        laboratorio: {
            name: "Laboratorio",
            to: "/dashboard/laboratorio",
            key: ":module",
        },
        preventiva: {
            name: "Preventiva",
            to: "/dashboard/preventiva",
            key: ":module",
        },
        "vendas-dibi": {
            name: "Vendas DIBI",
            to: "/dashboard/vendas-dibi",
            key: ":module",
        },
        add: {
            name: "Cadastro",
        },
        profile: {
            name:'Perfil'
        }
    },
};
