import classNames from "classnames";
import React, { useEffect } from "react";
import { Redirect, withRouter } from "react-router";
import { Button, ControlLabel, FormGroup, Input, InputGroup, Notification, Panel } from "rsuite";
import InputGroupAddon from "rsuite/lib/InputGroup/InputGroupAddon";
import InputGroupButton from "rsuite/lib/InputGroup/InputGroupButton";
import Logo from "../assets/mind.png";
import { AuthContext } from "../components/authProvider/main";
import { ConfirmModal } from "../components/modal/main";
import { LoginAnimationSvg } from "../svg/LoginAnimationSvg";
import axios from "../utilities/axios";
import qs from "query-string";
import { Helmet } from "react-helmet";

class ResetPassword extends React.Component<any, {}> {
    public state: any = {
        isLoading: false,
        userEmail: ""
    };


    sendEmail = () => {
        this.setState({
            isLoading: true
        })

        if (this.state.userEmail?.length <= 0) {
            Notification.error({
                title: "Email Inválido!"
            })

            this.setState({
                isLoading: false
            })
            return
        }

        axios.post('/api/v1/user/resetpassword', {
            user_email: this.state.userEmail
        }).then((res) => {
            Notification.success({
                title: res.data.message
            })
        }).catch((err) => {
            if (err && err.response && err.response.data.message) {
                this.setState({ errorMessage: err.response.data.message });

            }
        }).finally(() => {
            this.setState({ isLoading: false });
        });
    }


    render() {
        return (
            <div className="login-page">
                <Helmet>
                    <title>Fast Order - Esqueci Minha Senha</title>
                </Helmet>
                <LoginAnimationSvg />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Panel style={{ width: '350px', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f9f9f9', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', textAlign: 'center' }}>
                        <div style={{ margin: '0 auto 20px' }}>
                            <img alt={"Logo"} src={Logo} style={{ width: '160px' }} />
                        </div>
                        <form onSubmit={() => this.sendEmail()}>
                            <FormGroup className="mb-4">
                                <ControlLabel>E-mail</ControlLabel>
                                <InputGroup>
                                    <InputGroupAddon>
                                        <i className="fas fa-at"></i>
                                    </InputGroupAddon>
                                    <Input style={{ color: "black" }} onPressEnter={this.sendEmail} onChange={(v) => this.setState({ userEmail: v })}></Input>
                                </InputGroup>
                            </FormGroup>
                            <div style={{ position: 'relative', width: '100%' }}>
                                <small style={{ position: 'absolute', top: '-10px', color: 'red' }}>{this.state.errorMessage}</small>
                                <Button style={{ background: '#0096a0', width: '100%', marginTop: '5px', fontSize: '16px', border: 'none', borderRadius: '3px', cursor: 'pointer', padding: '10px', color: 'white' }} color="green" onClick={this.sendEmail} loading={this.state.isLoading}>
                                    Enviar Email de Recuperação
                                </Button>
                                <a href="/login">Lembrei minha senha</a>
                            </div>
                        </form>

                    </Panel>
                </div>
            </div>
        );
    }
}

export default withRouter(ResetPassword);
