import React from "react";
import { Message } from "rsuite";

// export

var errorHandler: any = {
    _subscriptions: [],
    errors: [],
    register: function (component: any) {
        this._subscriptions.push(component);
    },
    add: function (text: string) {
        for (var i in this._subscriptions) {
            if (this._subscriptions[i].add) {
                this._subscriptions[i].add(text);
            }
        }
    },
};

export const error = errorHandler;

export class ErrorList extends React.Component<any, {}> {
    public state = {
        errors: [],
    };

    public errorHandler: any;

    public add = (message: string) => {
        this.setState({ errors: [...this.state.errors, message] });
    };

    componentDidMount() {
        errorHandler.register(this);
    }

    render() {
        var output = [];
        for (var i in this.state.errors) {
            output.push(<Message key={i} className="col-md-12" type="error" description={this.state.errors[i]} closable />);
        }
        return (
            <div className={this.state.errors.length ? "container" : ""}>
                <div className="row">{output}</div>
            </div>
        );
    }
}
