import classNames from "classnames";
import React from "react";
import AnimateHeight from "react-animate-height";
import { ModuleContext } from "../authProvider/main";
import { Permission } from "../Permission";
import { throws } from "assert";
// import { Permission } from "../Permission";

interface ISidebarItem {
    title: string;
    color?: string;
    icon?: string;
    active?: boolean;
    history?: any;
    items?: ISidebarItem[];
    style?: React.CSSProperties;
    className?: string;
    isOpen?: boolean;
    onClick?: Function;
    bold?: boolean;
    page?: any;
}

export class SidebarItem extends React.Component<ISidebarItem, {}> {

    static contextType = ModuleContext;


    public state = {
        isOpen: this.props.isOpen ? this.props.isOpen : false,
        created: false,
    };

    renderIcon() {
        if (!this.props.icon) {
            return null;
        }
        return (
            <div className="icon">
                <i style={{ color: this.props.color ?? "#858585" }} className={"fa-fw far " + this.props.icon}></i>
            </div>
        );
    }

    renderChevron() {
        if (this.props.items) {
            return (
                <div className="chevron">
                    <i className={"fas " + (!this.state.isOpen ? "fa-chevron-down" : "fa-chevron-up")}></i>
                </div>
            );
        }
        return null;
    }

    onClick = (event: any) => {
        if (this.props.items) {
            this.setState({ isOpen: !this.state.isOpen });
        }
        if (this.props.onClick) {
            this.props.onClick(event);
        }
    };

    goTo = (to: string, newTab = false) => {
        var menuTarget = this.context.name;
        var target = ("/dashboard/" + menuTarget + "/" + to).toLocaleLowerCase();
        if (to[0] === "/") {
            target = to.replace("{module}", menuTarget).toLocaleLowerCase();
            if (newTab) {
                window.open(target, "_blank")?.focus();
            } else {
                this.props.history.push(target);
            }
            // menuTarget = menuTarget.replace('/','');
        } else {
            if (newTab) {
                window.open(target, "_blank")?.focus();
            } else {
                this.props.history.push(target);
            }
        }
        if (!newTab) {
            this.context.setPage(to);
        }
    };

    onMenuClick = (event: any, menu: any) => {
        //console.log({ event });

        if (event) {
            if (event.button === 1) {
                this.goTo(menu.to, true);
                return;
            }
        }
        this.goTo(menu.to, false);
    };

    renderChild() {
        // if (!this.state.isOpen) {
        //     return null;
        // }
        var rendered = [];
        if (this.props.items) {
            for (let i = 0; i < this.props.items?.length; i++) {
                const menu : any = this.props.items[i];
                rendered.push(
                    <Permission name={(menu.to.split("-").join("_") + "-browse").toLocaleLowerCase()}>
                        <SidebarItem
                            active={this.props.page === menu.to} page={this.props.page} 
                            bold={false} onClick={(e: any) => this.onMenuClick(e, menu)} key={i} 
                            isOpen={this.state.isOpen === false ? false : undefined} color={menu.color} 
                            icon={classNames("fas", "fa-" + menu.icon)} items={menu.items} title={menu.title} 
                            className={"children"} 
                        />
                    </Permission>
                );
            }
        }
        return (
            <AnimateHeight height={!this.state.isOpen ? 0 : "auto"}>
                <div className="subitems">{rendered}</div>
            </AnimateHeight>
        );
    }

    render() {
        return (
            
            <div className={classNames("item", this.props.active ? "active" : "", this.props.className, this.state.isOpen ? "open" : "")}>
                <div onMouseDown={this.onClick} className={"item-rendered " + (this.state.isOpen ? "active" : "")}>
                    {this.renderIcon()}
                    <div 
                        className="text" 
                        style={{ color: this.props.color ?? "#858585",left: !this.props.icon ? 15 : 30,fontWeight: this.props.bold !== false ? 'bold':'normal' }}
                    >
                        {this.props.title}
                    </div>
                    {this.renderChevron()}
                </div>
                    {this.renderChild()}
                {/* {this.props.slug} */}
            </div>

        );
    }
}
